<template>
  <v-container fluid>
    <data-table
      :headers="headers"
      :items="items"
      commit="SET_ALL_PRODUCTS"
      :editItem="editItem"
      :deleteItem="deleteItem"
    >
      <div slot="activator" class="row">
        <v-col cols="3">
          <v-card-title class="py-0 pl-0">
            {{ $t("shipping") }}
          </v-card-title>
        </v-col>
        <v-col cols="4" offset="3" class="text-right">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            placeholder="Search Category Name or Description"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn color="primary" @click="addNewItem">
            <v-icon small left> fal fa-plus </v-icon>
            New Category</v-btn
          >
        </v-col>
      </div>
    </data-table>
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";
export default {
  components: {
    DataTable,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Category Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Description",
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: "Actions",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      items: {
        data: [
          {
            id: 1,
            name: "Electronics",
            description: "Devices and gadgets",
          },
          {
            id: 2,
            name: "Books",
            description: "Printed and digital books",
          },
          {
            id: 3,
            name: "Clothing",
            description: "Men's and women's clothing",
          },
          {
            id: 4,
            name: "Home & Kitchen",
            description: "Appliances and kitchenware",
          },
          {
            id: 5,
            name: "Sports",
            description: "Sporting goods and equipment",
          },
          {
            id: 6,
            name: "Toys",
            description: "Children's toys and games",
          },
          {
            id: 7,
            name: "Beauty",
            description: "Beauty and personal care products",
          },
          {
            id: 8,
            name: "Automotive",
            description: "Car accessories and parts",
          },
          {
            id: 9,
            name: "Garden",
            description: "Gardening tools and plants",
          },
          {
            id: 10,
            name: "Health",
            description: "Health and wellness products",
          },
        ],
      },
    };
  },
  methods: {
    editItem(item) {
      console.log("Edit", item);
    },
    deleteItem(item) {
      console.log("Delete", item);
    },
    addNewItem() {
      console.log("Add New Product");
    },
  },
};
</script>

<style scoped></style>
