<template>
  <section>
    <data-table
      :headers="headers"
      :items="items"
      commit="SET_INVOICES"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
    >
      <div slot="activator" class="row justify-sm-space-between">
        <v-col cols="3">
          <v-card-title class="px-0 pt-0"> {{ $t("invoice") }} </v-card-title>
        </v-col>
        <v-col cols="4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </v-col>
        <v-col cols="2" class="text-end">
          <v-btn depressed color="primary" @click="newItem">
            <v-icon left>fal fa-plus-circle</v-icon>
            {{ $t("add_invoice") }}
          </v-btn>
        </v-col>
      </div>
    </data-table>
    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="details"
      width="750px"
      @submit="submitForm"
    >
      <div slot="activator">
        <div class="invoice-container" id="saveInvoice">
          <div class="header">
            <div class="logo-container">
              <div class="logo">
                <v-img
                  contain
                  width="80"
                  height="80"
                  src="/images/logo.png"
                ></v-img>
              </div>
              <div>
                <h1 class="company-name">PosSmart</h1>
                <p class="company-tagline">ລະບົບການຂາຍທີ່ທັນສະໄຫມ</p>
              </div>
            </div>
            <v-btn class="status-button" small depressed disabled>
              {{ $t(form.payment_status) }}
            </v-btn>
          </div>
          <v-divider></v-divider>

          <div class="invoice-details">
            <div class="client-info">
              <h2>{{ $t("invoice_to") }}:</h2>
              <h3>{{ form.store.name }}</h3>
              <p>{{ form.store.phone_no }}</p>
              <p>
                {{ form.store.address }}
              </p>
            </div>
            <div class="invoice-info">
              <p>
                <strong>{{ $t("invoice_no") }}.</strong>
                {{ form.invoice_id }}
              </p>
              <p>
                <strong>{{ $t("date") }}:</strong>
                {{ form.created_at | DatePrint }}
              </p>
            </div>
          </div>

          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th>{{ $t("no") }}.</th>
                  <th>{{ $t("item") }}</th>
                  <th class="text-center">{{ $t("quantity") }}</th>
                  <th class="text-right">{{ $t("price") }}</th>
                  <th class="text-right">{{ $t("total") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in form.invoice_detail" :key="index">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    {{ item.title }}
                  </td>
                  <td class="text-center">
                    {{ item.quantity | ToNumber }}
                  </td>
                  <td class="text-right">
                    {{ item.price | ToNumber }}
                  </td>
                  <td class="text-right">
                    {{ item.total | ToNumber }}
                  </td>
                </tr>
                <tr class="total-row">
                  <td colspan="4" style="text-align: right">Total</td>
                  <td class="text-right">
                    {{ form.total | NumberFormat }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="footer">
            <v-row>
              <v-col cols="6">
                <h2>Devla</h2>
                <p>ນາສ້າສ້ງໃຜ່, ໄຊເສດຖາ ນະຄອນຫຼວຫຼງວຽງຈັນ</p>
                <p>https://devla.la</p>
                <p>02028492947 | 02054444151</p>
              </v-col>
              <v-col cols="6" class="text-right">
                <div class="account-info">
                  <v-img width="80" height="80" src="/images/qr.png" />
                  <p>{{ account.name }} - {{ account.currency }}</p>
                  <p>
                    {{ account.number }}
                  </p>
                </div></v-col
              >
            </v-row>
          </div>
        </div>
        <div class="action">
          <v-btn depressed color="primary" @click="saveInvoice">
            <v-icon small left> fal fa-download </v-icon>
            {{ $t("save") }}
          </v-btn>
        </div>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :title="titleForm"
      @submit="submitForm"
    >
      <div slot="activator">
        <TextSelect
          label="store"
          v-model="form.store_id"
          itemValue="id"
          text="name"
          :items="stores"
        />
        <TextSelect label="status" v-model="form.status" :items="status" />
        <TextSelect
          label="payment_method"
          v-model="form.payment_method"
          :items="payment_method"
        />
        <TextSelect
          label="payment_status"
          v-model="form.payment_status"
          :items="payment_status"
        />
        <v-row class="mt-4">
          <v-col cols="6">
            <v-btn depressed small color="primary" @click="addMoreObject">
              <v-icon left>fal fa-plus-circle</v-icon>
              Add More
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn depressed small color="error" @click="removeItem(index)">
              <v-icon left>fal fa-minus-circle</v-icon>
              Remove
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <template v-for="(item, index) in form.invoice_detail">
          <section :key="index">
            <TTextField label="title" v-model="item.title" />
            <VuetifyMoney label="quantity" v-model="item.quantity" />
            <VuetifyMoney label="price" v-model="item.price" />
          </section>
        </template>
      </div>
    </app-custom-dialog>
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { invoiceHead } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import AppDetail from "@/components/products/details";
import { myMixin } from "@/mixin/main";
import { success, confirm } from "@/controllers/alert";
import { PACKAGE } from "@/validations/admin";
import VuetifyMoney from "@/components/VuetifyMoney";
import TextSelect from "@/components/global/TextSelect";
import html2pdf, { f } from "html2pdf.js";

export default {
  mixins: [PACKAGE, myMixin],
  data: () => ({
    update: false,
    dialog: false,
    dialogAdd: false,
    titleForm: "new_invoice",
    stores: [],
    items: {},
    search: "",
    status: ["pending", "shipped", "delivered", "cancelled"],
    payment_method: ["cash", "transfer", "other"],
    payment_status: ["pending", "paid", "failed"],
    formUpdate: {},
    form: {
      store: {},
      store_id: "",
      invoice_detail: [
        {
          title: "",
          quantity: 0,
          price: 0,
        },
      ],
    },
  }),
  async created() {
    await this.fetchItem();
  },
  mounted() {
    this.getAllStore();
  },
  components: {
    DataTable,
    TTextField,
    VuetifyMoney,
    AppDetail,
    TextSelect,
  },
  computed: {
    ...mapGetters({
      account: "index/account",
    }),
    headers() {
      return invoiceHead(this);
    },

    titleStatus() {
      return this.update ? "Update" : "New";
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const { data } = await this.$http.get(`invoices?search=${this.search}`);
      this.items = data.data;
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      this.titleForm = "new_invoice";
      this.form.status = "pending";
      this.form.payment_method = "transfer";
      this.form.payment_status = "pending";
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    async getAllStore() {
      const { data } = await this.$http.get(`get_all_store`);
      this.stores = data.data;
    },
    async editItem({ id }) {
      const { data } = await this.$http.get(`invoices/${id}`);
      this.form = data.data;
      this.form.store_id = data.data.store;
      this.update = true;
      this.titleForm = "update_invoice";
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ id }) {
      try {
        const { isConfirmed } = await confirm({
          props: this,
          text: "want_to_delete",
        });
        if (isConfirmed) {
          const { data } = await this.$http.delete(`invoices/${id}`);
          if (data.status) {
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await success(this, data.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async viewItem({ id }) {
      try {
        const { data } = await this.$http.get(`invoices/${id}`);
        this.form = data.data;
        this.dialog = !this.dialog;
      } catch (error) {
        console.error(error);
      }
    },
    async addMoreObject() {
      this.form.invoice_detail.push({
        title: "",
        quantity: 0,
        price: 0,
      });
    },

    async removeItem(index) {
      this.form.invoice_detail.splice(index, 1);
    },

    async submitForm() {
      var result;
      try {
        const body = {
          store_id: this.form.store_id.id,
          status: this.form.status,
          payment_method: this.form.payment_method,
          payment_status: this.form.payment_status,
          data: this.form.invoice_detail,
        };
        if (this.update) {
          result = await this.$http.put(`invoices/${this.form.id}`, body);
        } else {
          result = await this.$http.post("invoices", body);
        }
        if (result.data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          this.dialogAdd = !this.dialogAdd;
          await success(this, result.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async saveInvoice() {
      try {
        const element = document.getElementById("saveInvoice");
        const opt = {
          margin: 0.2,
          filename: `invoice_${this.form.invoice_id}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
          pagebreak: { mode: ["avoid-all", "css", "legacy"] },
          html2canvas: {
            scale: 2,
            useCORS: true,
            onclone: (document) => {
              const style = document.createElement("style");
              style.innerHTML = `
                @font-face {
                  font-family: 'NotoSansLaoUI';
                  src: url('../fonts/NotoSansLaoLooped-Regular.ttf') format('truetype');
                }
                body {
                  font-family: "Poppins", "NotoSansLaoUI", sans-serif !important;
                }
              `;
              document.head.appendChild(style);
            },
          },
        };
        html2pdf().from(element).set(opt).save();
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice-container {
  width: 100%;
  margin: 0 auto;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  position: relative;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.company-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
}

.company-tagline {
  color: #4a5568;
  margin-top: 0.25rem;
}

.status-button {
  padding: 0.5rem 1rem;
}

.invoice-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.client-info h2 {
  color: #6b46c1;
  margin-bottom: 0.5rem;
}

.invoice-info {
  text-align: right;
}

.table-container {
  margin: 2rem 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  background-color: #6b46c1;
  color: white;
  padding: 1rem;
  text-align: left;
}

td {
  padding: 1rem;
  border-bottom: 1px solid #e2e8f0;
}

.total-row {
  font-weight: bold;
}

.footer {
  margin-top: 4rem;
  text-align: left;
  color: #4a5568;
}

.footer .row .col h2 {
  color: #2d3748;
  margin-bottom: 0.5rem;
}

.footer .row .col p {
  color: #718096;
  margin-bottom: 0.5rem;
}
.footer .row .col .account-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.action {
  text-align: center;
  margin-top: 1rem;
}
</style>
