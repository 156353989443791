<template>
  <v-img
    class="rounded-lg"
    :src="image"
    :height="height"
    :width="width"
    :contain="contain"
  >
  </v-img>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    img: { required: true },
    contain: { type: Boolean, default: false },
    height: { type: String, default: "50" },
    width: { type: String, default: "50" },
  },
  computed: {
    image() {
      return Help.img(this.img);
    },
  },
};
</script>

<style></style>
