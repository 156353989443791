<template>
  <div class="col-ms-12 col-md-5 col-lg-5 col-xl-5">
    <v-card class="rounded-lg">
      <v-card-title>{{ $t("product_detail") }} </v-card-title>
      <v-card-text class="text-center">
        <v-avatar
          @click="openURL"
          tile
          size="300"
          :color="Img ? 'white' : '#f5f5f5'"
          class="rounded-lg"
        >
          <v-img v-if="Img" contain :src="Img" width="500" height="400" />
          <h2 v-else class="pt-2 mb-0 black--text">500x400</h2>
        </v-avatar>

        <p class="pt-2 mb-0">{{ $t("size") }} 500x400</p>
        <p class="mt-0">{{ $t("extension") }} JPEG,JPG,PNG</p>
      </v-card-text>
      <v-card-text>
        <v-divider class="px-4 mt-0" />
        <h6 class="pb-3">{{ $t("id") }} : {{ form.code }}</h6>
        <h6 class="pb-3">{{ $t("product_name") }} : {{ form.name }}</h6>
        <h6 class="pb-3">
          {{ $t("stock") }} : {{ form.stock | NumberFormat }}
        </h6>
        <h6 class="pb-3">
          {{ $t("notify_stock") }} : {{ form.notify_stock | NumberFormat }}
        </h6>
        <h6 class="pb-3">
          {{ $t("price_buy") }} : {{ form.price_buy | NumberFormat }}
        </h6>
        <h6 class="pb-3">
          {{ $t("price_sale") }} : {{ form.price_sale | NumberFormat }}
        </h6>
        <template v-if="taxInclude">
          <h6 class="pb-3">
            {{ $t("tax") }} {{ tax }} : {{ tax_amount | NumberFormat }}
          </h6>
          <h6 class="pb-3">
            {{ $t("price_with_tax") }} :
            {{ price_with_tax | NumberFormat }}
          </h6>
        </template>
        <h6 class="pb-3">{{ $t("category") }} : {{ category }}</h6>
        <h6 class="pb-3">{{ $t("unit") }} : {{ size }}</h6>
        <h6 class="pb-3">{{ $t("description") }} : {{ form.desc }}</h6>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { myMixin } from "@/mixin/main";
export default {
  mixins: [myMixin],

  props: {
    form: {
      required: true,
      type: Object,
    },
    imgFile: {
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    form: {
      handler() {
        //  check if price_sale change the tax amount and price_sale will change
        // if (this.form.price_sale) {
        //   const price_sale = Number(this.form.price_sale);
        //   const tax_amount = Number(this.form.tax_amount);
        //   this.form.tax_amount = this.TaxCal(price_sale);
        //   this.form.price_sale = price_sale + tax_amount;
        // }
      },
      deep: true,
    },
  },
  computed: {
    Img() {
      // check if this.imgFile type is a string and includes http
      const type = typeof this.imgFile;
      if (this.imgFile && type === "string" && this.imgFile.includes("http")) {
        return this.imgFile;
      } else if (this.imgFile) {
        let file = null;
        // check if imgFile is a array and contains more then 0 element then select 1st element
        if (Array.isArray(this.imgFile) && this.imgFile.length > 0) {
          file = this.imgFile[0];
        } else {
          file = this.imgFile;
        }
        let imageURL = URL.createObjectURL(file);
        return imageURL;
      } else if (this.form.img) {
        return this.img(this.form);
      } else {
        return null;
      }
    },
    size() {
      if (this.form && this.form.size && this.form.size.name) {
        return this.form.size.name;
      } else if (this.form && this.form.size_id && this.form.size_id.name) {
        return this.form.size_id.name;
      } else {
        return null;
      }
    },
    category() {
      if (this.form && this.form.category && this.form.category.la_name) {
        return this.form.category.la_name;
      } else if (
        this.form &&
        this.form.category_id &&
        this.form.category_id.la_name
      ) {
        return this.form.category_id.la_name;
      } else {
        return null;
      }
    },
    tax_amount() {
      if (this.form && this.form.price_sale) {
        // cvonvert to number and price_sale *
        const price_sale = Number(this.form.price_sale);
        const amt = (price_sale * this.tax_rate) / 100;
        return amt;
      } else {
        return 0;
      }
    },
    price_with_tax() {
      if (this.form && this.form.price_sale) {
        // convert to number
        const price_sale = Number(this.form.price_sale);
        const tax_amount = Number(this.tax_amount);
        return price_sale + tax_amount;
      } else {
        return 0;
      }
    },
  },
  methods: {
    openURL() {
      // check if isEdit is true
      if (!this.isEdit) {
        //  open the image in a new tab with  Img from computed property
        window.open(this.Img, "_blank").focus();
        return;
      }
      const baseURL = process.env.VUE_APP_BASE_URL;
      const url = `${baseURL}/uploads/${this.form.img}`;
      window.open(url, "_blank").focus();
    },
  },
};
</script>

<style></style>
