<template>
  <div>
    <div class="pb-2">{{ $t(label) }}</div>
    <v-file-input
      accept="image/jpeg,image/png"
      show-size
      type="file"
      prepend-icon="fal fa-camera"
      color="primary"
      :flat="flat"
      :rounded="rounded"
      :outlined="outlined"
      :dense="dense"
      :disabled="disabled"
      :hide-details="hideDetails"
      v-model="model"
      :error-messages="errorMessages"
      :counter="counter"
      :filled="filled"
      :multiple="multiple"
    />
    <div v-if="hideDetails" class="mb-2" />
  </div>
</template>

<script>
export default {
  props: {
    flat: { type: Boolean, default: false },
    label: { type: String, require: true },
    errorMessages: { type: Array, require: true },
    outlined: { type: Boolean, default: true },
    dense: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    counter: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
  },
  data() {
    return {
      model: this.value,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.model = newVal;
    },
    model(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit("input", newVal);
    },
  },
  computed: {
    hideDetails() {
      if (this.errorMessages && this.errorMessages.length) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style></style>
