<template>
  <v-container fluid>
    <data-table
      :headers="headers"
      :items="category"
      commit="SET_CATEGORY"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
    >
      <div slot="activator" class="row justify-content-between">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <v-card-title class="px-0 pt-0"> {{ $t("category") }} </v-card-title>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 offset-lg-5">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 text-end">
          <v-btn depressed color="primary" @click="newItem">
            <v-icon left>fal fa-plus-circle</v-icon>
            {{ $t("new_data") }}
          </v-btn>
        </div>
      </div>
    </data-table>

    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="4">{{ $t("id") }}</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">{{ $t("lao_name") }}</v-col>
          <v-col cols="8">{{ form.la_name }}</v-col>
          <v-col cols="4">{{ $t("eng_name") }}</v-col>
          <v-col cols="8">{{ form.en_name }}</v-col>
          <v-col cols="4">{{ $t("date") }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField
          label="la_name"
          :errorMessages="nameError"
          v-model="form.la_name"
          @input="$v.form.la_name.$touch()"
          @blur="$v.form.la_name.$touch()"
        />
        <TTextField label="en_name" v-model="form.en_name" />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { productCategory } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import { CategoryValidate } from "@/validations/shop";
import { success, confirm } from "@/controllers/alert";
import { myMixin } from "@/mixin/main";

export default {
  mixins: [CategoryValidate, myMixin],
  data: () => ({
    update: false,
    dialogAdd: false,
    dialog: false,
    overlay: false,
    search: "",
    form: {
      is_online: 1,
    },
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField },
  computed: {
    ...mapGetters({
      category: "index/category",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    headers() {
      return productCategory(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `categories_paginate?q=${this.search}&is_online=1`,
        commit: "SET_CATEGORY",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },

    editItem(item) {
      this.update = true;
      this.form = item;
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`categories/${id}`);
        if (data.status) {
          await Help.clearForm(this.form, this);
          // find the id in array and remove it
          this.category.data = this.category.data.filter(
            (item) => item.id !== id
          );
          await success(this, data.message);
        }
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async submitForm() {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          const body = {
            la_name: this.form.la_name,
            en_name: this.form.en_name,
            is_online: 1,
            is_cooking: 0,
          };

          if (this.update) {
            const { data } = await this.$http.put(
              `categories/${this.form.id}`,
              body
            );
            if (data.status) {
              this.dialogAdd = !this.dialogAdd;
              await Help.clearForm(this.form, this);
              await this.fetchItem();
              await success(this, data.message);
            }
          } else {
            const { data } = await this.$http.post("categories", body);
            if (data.status) {
              this.dialogAdd = !this.dialogAdd;
              await Help.clearForm(this.form, this);
              // add products_count : 0 in data before push
              data.data.products_count = 0;
              // unshift new item
              this.category.data.push(data.data);
              await success(this, data.message);
            }
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
