<template>
  <v-navigation-drawer
    :mini-variant="miniVariant"
    :clipped="clipped"
    v-model="drawer"
    fixed
    app
    class="drawer-left"
    width="255"
  >
    <template v-slot:prepend v-if="user && user.first_name">
      <v-list-item class="px-2">
        <v-badge bordered bottom color="green" dot offset-x="25" offset-y="20">
          <v-list-item-avatar>
            <v-avatar color="#f0f0f0">
              <v-img :src="Img" />
            </v-avatar>
          </v-list-item-avatar>
        </v-badge>

        <v-list-item-content>
          <v-list-item-title> {{ user.first_name }}</v-list-item-title>
          <v-list-item-subtitle>
            {{
              user.branch_id ? user.branch_id.name : user.email
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                depressed
                small
                v-bind="attrs"
                v-on="on"
                @click="askForJoin"
              >
                <v-icon small color="grey">fal fa-store</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t("e_commerce") }}
            </span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </template>

    <v-list v-if="menus.length">
      <template v-for="(item, i) in menus">
        <v-list-group
          v-if="item.menu.length"
          :key="item.name_la"
          sub-group
          no-action
          :value="item.value"
        >
          <template v-slot:prependIcon>
            <v-icon small>{{ item.icon }}</v-icon>
          </template>
          <template v-slot:appendIcon>
            <v-icon small>fal fa-chevron-down</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-title>
              {{
                $i18n.locale === "en" ? item.name_en : item.name_la
              }}</v-list-item-title
            >
          </template>
          <v-list-item
            v-for="(data, key) in item.menu"
            :key="key"
            :to="data.to"
            link
          >
            <v-list-item-icon>
              <v-icon small>{{ data.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $i18n.locale === "en" ? data.name_en : data.name_la
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item v-else :key="i + 1" :to="item.to">
          <v-list-item-icon>
            <v-icon small>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            $i18n.locale === "en" ? item.name_en : item.name_la
          }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <v-divider class="ma-0" />
      <div class="pa-2 text-center">
        {{ miniVariant ? version : `${$t("version")} : ${version}` }}
      </div>
    </template>
    <app-custom-dialog
      v-model="dialog"
      :title="form.title"
      @submit="joinSubmit"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="12">
            <v-alert type="info" outlined>
              {{ $t("join_e_commerce_description") }}
            </v-alert>
          </v-col>
          <v-col cols="6" v-for="(item, i) in packages" :key="i">
            <v-card class="pa-2" outlined height="250px">
              <v-card-title class="text-center text-capitalize">{{
                item.name
              }}</v-card-title>
              <v-card-text class="text-center">
                <span class="text-h6 font-weight-bold">{{
                  item.price | NumberFormat
                }}</span>
                {{ $t("lak") }}/
                <span>
                  {{ $t("month") }}
                </span>

                <p>{{ $t(item.desc) }}</p>
              </v-card-text>
              <v-card-actions class="text-center">
                <v-btn
                  block
                  depressed
                  class="text-capitalize"
                  :color="form.package === item.value ? 'primary' : 'grey'"
                  @click="form.package = item.value"
                >
                  <v-icon small left>{{ item.icon }}</v-icon>
                  {{ $t(item.btn) }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </app-custom-dialog>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { myMixin } from "@/mixin/main";
import { toast, confirm } from "@/controllers/alert";
const drawer = localStorage.getItem("drawer") === "true" ? true : false;

export default {
  data() {
    return {
      clipped: false,
      drawer: drawer,
      miniVariant: false,
      version: process.env.VUE_APP_VERSION,
      dialog: false,
      form: {
        check: false,
        title: "terms_and_conditions",
        text: "terms_and_conditions_description",
        con_txt: "agree",
        can_txt: "cancel",
        price: 0,
        try: 3,
        package: 1,
      },
      packages: [
        {
          value: 1,
          name: "free",
          price: 0,
          try: "3 Months",
          btn: "get_free",
          desc: "free_try_3_month",
          icon: "fal fa-store",
        },
        {
          value: 2,
          name: "pro",
          price: 100000,
          try: 0,
          btn: "get_pro",
          desc: "pro_description",
          icon: "fal fa-file-invoice-dollar",
        },
      ],
    };
  },

  watch: {
    drawer(val) {
      localStorage.setItem("drawer", val);
    },
  },
  mixins: [myMixin],
  created() {
    if (this.$vuetify.breakpoint.mobile) {
      this.drawer = false;
    }
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      isLoggedIn: "auth/isLoggedIn",
    }),
    Img() {
      return Help.img(this.user.avatar);
    },
    menus() {
      const data = Help.addValueKey(this.user.auth_menus);
      return data;
    },
  },
  methods: {
    async askForJoin() {
      try {
        const { data } = await this.$http.post("check_join");
        if (!data.data) {
          // join the to online store
          const { isConfirmed } = await confirm({
            props: this,
            title: "join_e_commerce",
            text: "join_e_commerce_description",
            con_txt: "join",
            can_txt: "later",
            color_can: "red",
          });
          if (isConfirmed) {
            this.dialog = true;
          }
        } else {
          // push to online path
          const host = window.location.origin;
          const url = `${host}/dashboard/index`;
          window.open(url, "_blank");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async joinSubmit() {
      try {
        // find the package by form.package
        const _pack = this.packages.find(
          (item) => item.value === this.form.package
        );
        const body = {
          price: _pack.price,
          type: _pack.name,
        };
        const { data } = await this.$http.post("request_join", body);
        if (data.status) {
          await toast(this, data.message, "success");
          this.dialog = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.row .col .v-card .v-card__actions {
  padding: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}
</style>
