<template>
  <div class="row">
    <app-preview
      v-if="$vuetify.breakpoint.mobile"
      :form="form"
      :imgFile="imgFile"
    />

    <div class="col-ms-12 col-md-7 col-lg-7 col-xl-7">
      <v-card class="rounded-lg pb-5">
        <v-form>
          <v-card-title>{{ $t("edit_product") }}</v-card-title>
          <v-card-text
            :class="$vuetify.breakpoint.mobile ? '' : 'px-10 pt-5 pb-0'"
          >
            <TTextField label="barcode" v-model="form.code" />
            <FileInput label="image" v-model="imgFile" :multiple="true" />

            <TTextField label="product_name" v-model="form.name" />

            <v-row>
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                <TTextField
                  :disabled="true"
                  label="stock"
                  v-model="form.stock"
                />
              </v-col>
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                <TTextField
                  type="number"
                  label="stock_notify"
                  v-model="form.notify_stock"
              /></v-col>
            </v-row>
            <v-row>
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                <VuetifyMoney v-model="form.price_buy" label="price_buy"
              /></v-col>
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                <VuetifyMoney v-model="form.price_sale" label="price_sale"
              /></v-col>
            </v-row>

            <TextSelect
              label="category"
              v-model="form.category_id"
              itemValue="id"
              text="la_name"
              :items="categories"
            />
            <TextSelect
              label="unit"
              v-model="form.size_id"
              itemValue="id"
              text="name"
              :items="sizes"
            />
            <TextSelect
              label="supplier"
              v-model="form.vendor_id"
              itemValue="id"
              text="name"
              :items="suppliers"
            />
            <CheckBox
              class="expired-check"
              v-model="expiredPro"
              label="expired_product"
            />
            <template v-if="expiredPro">
              <DatePicker label="expired_date" v-model="form.expired_date" />
            </template>
            <TextArea label="description" v-model="form.desc" />
          </v-card-text>
          <v-card-actions class="my-4">
            <v-spacer />
            <v-btn
              @click="clearForm()"
              large
              dark
              class="px-8"
              color="#ff0000"
              depressed
            >
              <v-icon left> fal fa-times</v-icon> {{ $t("clear_form") }}
            </v-btn>
            <v-btn
              @click="submitForm(form)"
              color="primary"
              large
              dark
              class="px-8"
              depressed
            >
              <v-icon left> fal fa-upload</v-icon> {{ $t("update") }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
    <app-preview
      v-if="!$vuetify.breakpoint.mobile"
      :form="form"
      :imgFile="imgFile"
    />
    <app-loading :overlay="overlay" />
  </div>
</template>

<script>
import TTextField from "@/components/global/TextField";
import TextSelect from "@/components/global/TextSelect";
import TextArea from "@/components/global/TextArea";
import { mapGetters, mapActions } from "vuex";
import AppPreview from "@/components/products/preview";
import VuetifyMoney from "@/components/VuetifyMoney";
import { success, error } from "@/controllers/alert";
import FileInput from "@/components/global/FileInput";
import { confirm } from "@/controllers/alert";
import CheckBox from "@/components/global/CheckBox";

export default {
  data: () => ({
    imgFile: null,
    overlay: false,
    expiredPro: false,
    form: {},
  }),
  components: {
    TextSelect,
    TTextField,
    VuetifyMoney,
    FileInput,
    TextArea,
    AppPreview,
    CheckBox,
  },
  computed: {
    ...mapGetters({
      category: "index/category",
      suppliers: "index/suppliers",
      sizes: "index/sizes",
    }),
    categories() {
      if (
        this.category != null &&
        this.category != undefined &&
        this.category.length
      ) {
        return this.category;
      } else {
        return [];
      }
    },
  },
  created() {
    this.fetchSingleProduct();
    this.fetchSize();
    this.fetchVendor();
    this.fetchCategory();
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchSize() {
      const body = {
        that: this,
        url: "product_size",
        commit: "SET_SIZES",
      };
      await this.fetch(body);
    },
    async fetchVendor() {
      const body = {
        that: this,
        url: "suppliers",
        commit: "SET_SUPPLIERS",
      };
      await this.fetch(body);
    },
    async fetchCategory() {
      const body = {
        that: this,
        url: "categories",
        commit: "SET_CATEGORY",
      };
      await this.fetch(body);
    },
    async submitForm(form) {
      try {
        const { id } = this.$route.params;
        this.overlay = true;
        let formData = new FormData();
        formData.append("code", form.code);
        formData.append("name", form.name);
        // check if imgFile is more then one file then loop through and append to formData
        if (Array.isArray(this.imgFile) && this.imgFile.length > 0) {
          for (let i = 0; i < this.imgFile.length; i++) {
            formData.append("file[]", this.imgFile[i]);
          }
        } else {
          formData.append("file", this.imgFile);
        }
        formData.append("desc", form.desc);
        formData.append("notify_stock", form.notify_stock);
        formData.append("price_sale", form.price_sale);
        formData.append("price_buy", form.price_buy);
        // check if category_id is object
        const category_id = form?.category_id?.id
          ? form.category_id.id
          : form.category_id;
        const vendor_id = form?.vendor_id?.id
          ? form.vendor_id.id
          : form.vendor_id;
        const size_id = form?.size_id?.id ? form.size_id.id : form.size_id;
        formData.append("category_id", category_id);
        formData.append("vendor_id", vendor_id);
        formData.append("size_id", size_id);
        const { data } = await this.$http.post(
          `products_update/${id}`,
          formData,
          Help.headerFile()
        );
        this.overlay = false;
        if (data.status) {
          this.clearData();
          await success(this, data.message);
        }
      } catch (error) {
        this.overlay = false;
        console.log(error);
      }
    },
    async fetchSingleProduct() {
      try {
        this.overlay = true;
        const { id } = this.$route.params;
        const { data } = await this.$http.get(`products/${id}`);
        if (data.status) {
          const _data = data.data;
          const sale_price = _data.price_sale - _data.tax_amount;
          this.$set(this.form, "code", _data.code);
          this.$set(this.form, "img", _data.img);
          this.$set(this.form, "name", _data.name);
          this.$set(this.form, "desc", _data.desc);
          this.$set(this.form, "stock", _data.stock);
          this.$set(this.form, "notify_stock", _data.notify_stock);
          this.$set(this.form, "price_buy", _data.price_buy);
          this.$set(this.form, "price_sale", sale_price);
          this.$set(this.form, "tax_amount", _data.tax_amount);
          this.$set(this.form, "vendor_id", _data.vendor.id);
          this.$set(this.form, "category_id", _data.category.id);
          this.$set(this.form, "size_id", _data.size.id);
          this.$set(this.form, "category", _data.category);
          this.$set(this.form, "size", _data.size);
          if (_data.expired_date) {
            this.$set(this.form, "expired_date", _data.expired_date);
            this.expiredPro = true;
          }
          this.overlay = false;
        }

        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        console.log(error);
      }
    },
    async clearForm() {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_clear_form",
      });

      if (isConfirmed) {
        this.clearData();
        this.imgFile = null;
      }
    },
    clearData() {
      this.form = {
        code: "",
        img: "",
        name: "",
        desc: "",
        stock: 0,
        notify_stock: 0,
        price_buy: 0,
        price_sale: 0,
        tax_amount: 0,
        price_sale: 0,
        vendor_id: "",
        category_id: "",
        size_id: "",
        category: {},
        size: {},
      };
      this.imgFile = null;
      this.expiredPro = false;
    },
  },
};
</script>

<style></style>
