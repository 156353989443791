<template>
  <v-app dark>
    <app-loading :overlay="overlay" />
    <app-drawer-left ref="drawer" :menus="menus" />
    <v-app-bar flat height="63" fixed app>
      <v-btn
        small
        fab
        elevation="0"
        @click.stop="$refs.drawer.drawer = !$refs.drawer.drawer"
      >
        <v-icon dark small>fal fa-stream</v-icon>
      </v-btn>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mobile"
        @click.stop="$refs.drawerRight.drawer = !$refs.drawerRight.drawer"
      />
      <v-spacer />
      <v-menu offset-y bottom right>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            fab
            depressed
            small
            v-bind="attrs"
            v-on="on"
            class="font-weight-medium"
          >
            <v-icon small> fal fa-globe </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(locale, index) in locales"
            :key="index"
            @click="changeLocale(locale.code)"
          >
            <v-list-item-avatar size="20">
              <v-img :src="locale.flag" />
            </v-list-item-avatar>

            <v-list-item-title>{{ locale.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn class="ml-3" fab depressed small @click="changeTheme">
        <v-icon small>fal fa-{{ darkMode === true ? "sun" : "moon" }}</v-icon>
      </v-btn>
      <v-btn class="ml-3" fab depressed small @click="logoutMethod">
        <v-icon small>fal fa-sign-out-alt</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="overflow-y-auto">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppDrawerLeft from "@/components/layouts/drawerLeftOnline";
import { mapGetters, mapActions } from "vuex";
import { myMixin } from "@/mixin/main";
import { setUser, checkToken } from "@/controllers/auth";
import { confirm } from "@/controllers/alert";

export default {
  components: { AppDrawerLeft },
  mixins: [myMixin],
  data() {
    return {
      darkMode: false,
      menus: [
        {
          name: "dashboard",
          icon: "fal fa-tachometer-alt",
          to: "/dashboard/index",
        },
        {
          name: "orders",
          icon: "fal fa-shopping-cart",
          to: "/dashboard/orders",
        },

        {
          name: "products",
          icon: "fal fa-boxes",
          to: "/dashboard/products",
        },
        {
          name: "categories",
          icon: "fal fa-list",
          to: "/dashboard/categories",
        },
        {
          name: "banners",
          icon: "fal fa-images",
          to: "/dashboard/banners",
        },
        {
          name: "payments",
          icon: "fal fa-credit-card",
          to: "/dashboard/payments",
        },
        {
          name: "followers",
          icon: "fal fa-users",
          to: "/dashboard/users",
        },
        {
          name: "settings",
          icon: "fal fa-cogs",
          to: "/dashboard/settings",
        },
      ],
    };
  },

  created() {
    checkToken(this);
    const thems = localStorage.getItem("darkMode");
    if (thems) {
      if (thems.length === 4) {
        this.$vuetify.theme.dark = true;
        this.darkMode = true;
      } else {
        this.$vuetify.theme.dark = false;
        this.darkMode = false;
      }
    }
  },
  mounted() {
    setUser(this);
    this.checkStore();
  },

  watch: {
    darkMode(value) {
      this.$vuetify.theme.dark = value;
      localStorage.setItem("darkMode", value);
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      overlay: "index/overlay",
      locales: "auth/locales",
    }),
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    async checkStore() {
      try {
        const { data } = await this.$http.post("check_join");
      } catch (error) {
        setTimeout(() => {
          if (window.opener) {
            window.close();
          }
        }, 3000);
        console.log(error);
      }
    },
    changeTheme() {
      this.darkMode = !this.darkMode;
      const value = !this.$vuetify.theme.dark;
      this.$vuetify.theme.dark = value;
      localStorage.setItem("darkMode", value);
    },
    changeLocale(code) {
      localStorage.setItem("locale", code);
      this.$i18n.locale = code;
    },
    async logoutMethod() {
      try {
        const { isConfirmed } = await confirm({
          props: this,
          text: "want_to_logout",
        });
        if (isConfirmed) {
          await this.logout(this);
          window.location.href = "/";
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
