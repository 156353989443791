<template>
  <section>
    <v-row class="mb-3">
      <template v-if="summaries.length">
        <v-col
          v-for="(item, index) in summaries"
          :key="index"
          class="col-xs-6 col-sm-4 col-md-3 cols-5"
        >
          <v-card rounded="lg">
            <v-card-text
              :class="$vuetify.breakpoint.mdAndUp ? 'px-4' : 'px-0'"
              class="px-0"
            >
              <v-list-item>
                <v-list-item-avatar :color="item.color" rounded="lg">
                  <v-icon color="white">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $t(item.title) }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="text-h6 font-weight-bold">
                    {{ item.total | NumberFormat }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
      <template v-else>
        <v-col v-for="(item, index) in 5" :key="index">
          <v-card rounded class="rounded-lg">
            <v-card-text class="pa-2">
              <v-skeleton-loader type="list-item-avatar-three-line" />
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>

    <data-table
      :headers="headers"
      :items="billReports"
      commit="SET_BILL_REPORT"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
    >
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1">
          <v-card-title class="px-0 pt-0">{{ $t("ticket") }} </v-card-title>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <TextSelect
            :dense="true"
            :placeholder="false"
            :items="statusList"
            label="status"
            itemValue="value"
            v-model="status"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <DatePicker
            :dense="true"
            :placeholder="false"
            label="start_date"
            v-model="start_date"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <DatePicker
            :dense="true"
            :placeholder="false"
            label="end_date"
            v-model="end_date"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <v-btn
            depressed
            outlined
            class="text-capitalize mr-2"
            color="primary"
            @click="filterDate(start_date, end_date)"
          >
            <v-icon left small>fal fa-search</v-icon>
            {{ $t("filter") }}
          </v-btn>
          <v-btn
            depressed
            class="text-capitalize"
            color="primary"
            @click="exportToExcel(start_date, end_date)"
          >
            <v-icon left small>fal fa-receipt</v-icon>
            Excel
          </v-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
      </div>
    </data-table>
    <app-custom-dialog
      width="900px"
      v-model="dialog"
      :footer="false"
      title="description"
    >
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>
    <!-- bill print -->
    <app-bill v-show="print" :item="form" />

    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      title="form_refund"
      @submit="submitForm"
    >
      <div slot="activator">
        <TextSelect
          label="con_fund"
          :errorMessages="return_typeError"
          v-model="body.return_type"
          @input="$v.body.return_type.$touch()"
          @blur="$v.body.return_type.$touch()"
          :returnObject="false"
          itemValue="value"
          :items="categories"
        />
        <TextArea
          label="noted"
          :errorMessages="remarkError"
          v-model="body.remark"
          @input="$v.body.remark.$touch()"
          @blur="$v.body.remark.$touch()"
        />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { billReports } from "@/utils/head";
import AppDetail from "@/components/products/billReport";
import { myMixin } from "@/mixin/main";
import AppBill from "@/components/print/bill";
import { returnProduct } from "@/validations/shop";
import TextArea from "@/components/global/TextArea";
import TextSelect from "@/components/global/TextSelect";
import { toast, error } from "@/controllers/alert";
import { ticketStatus, refundList } from "@/utils/mock";

// get today date
const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substr(0, 10);

// get tomorrow date
const tomorrow = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60000 + 86400000
)
  .toISOString()
  .substr(0, 10);

export default {
  mixins: [returnProduct, myMixin],

  data: () => ({
    search: "",
    overlay: false,
    dialog: false,
    print: false,
    summaries: [],
    form: {},
    status: {
      text: "all",
      value: "all",
    },
    start_date: today,
    end_date: tomorrow,
    menu: false,
    menu2: false,
    dialogAdd: false,

    body: {
      return_type: "not_match",
      ticket_id: null,
      remark: "",
    },
  }),
  async created() {
    await this.fetchSummary();
    await this.fetchItem();
  },
  components: {
    DataTable,
    TextSelect,
    AppDetail,
    TextArea,
    AppBill,
  },
  computed: {
    ...mapGetters({
      billReports: "index/billReports",
    }),
    categories() {
      return refundList(this);
    },
    headers() {
      return billReports(this);
    },
    statusList() {
      return ticketStatus(this);
    },
  },
  watch: {
    status(val) {
      this.fetchItem();
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchSummary() {
      try {
        const param = `start_date=${this.start_date}&end_date=${this.end_date}`;
        const { data } = await this.$http.get(`/get_summary_bill?${param}`);
        if (data.status) {
          this.summaries = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async filterDate(start_date, end_date) {
      try {
        await Help.getDifferentMonth(this, start_date, end_date);
        this.fetchItem();
        this.fetchSummary();
      } catch (err) {
        console.log("error", err.message);
        error(this, err.message);
        return;
      }
    },
    async fetchItem() {
      const body = {
        that: this,
        url: `bill_report?status=${this.status.value}&start_date=${this.start_date}&end_date=${this.end_date}&q=${this.search}`,
        commit: "SET_BILL_REPORT",
      };
      await this.fetch(body);
    },

    async deleteItem({ id }) {
      const { data } = await this.$http.get(`bill_detail/${id}`);
      if (data.status) {
        this.body.ticket_id = id;
        this.dialogAdd = true;
      }
    },
    async viewItem({ id }) {
      const { data } = await this.$http.get(`bill_detail/${id}`);
      this.form = data.data;
      this.dialog = true;
    },

    async editItem({ id }) {
      const { data } = await this.$http.get(`bill_detail/${id}`);
      this.form = data.data;
      // setTimeOut for 1 second to show overlay
      setTimeout(() => {
        setTimeout(() => {
          this.$htmlToPaper("printBill");
        }, 500);
      }, 500);
    },
    async submitForm() {
      try {
        this.$v.body.$touch();
        if (!this.$v.body.$invalid) {
          const { data } = await this.$http.post("cancel_ticket", this.body);
          if (data.status) {
            this.dialogAdd = !this.dialogAdd;
            await Help.clearForm(this.body, this);
            await toast(this, "cancel_ticket_success");
            await this.fetchItem();
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async exportToExcel(start_date, end_date) {
      try {
        await Help.getDifferentMonth(this, start_date, end_date);
      } catch (err) {
        console.log("error", err.message);
        error(this, err.message);
        return;
      }
      try {
        const url = `download_bill_report?status=${this.status.value}&start_date=${start_date}&end_date=${end_date}`;
        const data = await this.$http.get(url, { responseType: "blob" });
        const downloadUrl = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        // file name 01-04-2021
        const date = new Date();
        const fileName = `${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}.xlsx`;
        link.setAttribute("download", fileName); //any other extension
        document.body.appendChild(link);
        link.click();
        // Remove the link from the body
        document.body.removeChild(link);
        await toast(this, "export_to_excel_success");
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
