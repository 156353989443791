<template>
  <div class="container-xl pa-0">
    <v-row>
      <v-col cols="3">
        <v-card class="rounded-lg">
          <v-card-text>
            <v-tabs v-model="tab" vertical>
              <v-tab
                v-for="(item, index) in tabs"
                :key="index"
                class="text-left"
              >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon small left>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t(item.text) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-tab>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-tabs-items v-model="tab" class="rounded-lg v-card">
          <v-tab-item>
            <AppStoreDetail :store="user.stores" />
          </v-tab-item>
          <v-tab-item>
            <AppStore />
          </v-tab-item>

          <v-tab-item>
            <AppChangePassword />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppStoreDetail from "@/components/settings/store-detail";
import AppStore from "@/components/settings/store";
import { mapGetters } from "vuex";
import AppChangePassword from "@/components/settings/change-password";
export default {
  components: {
    AppStoreDetail,
    AppStore,
    AppChangePassword,
  },
  data() {
    return {
      // tabs: ["User Information", "Store Information", "Change Password"],
      tabs: [
        {
          icon: "fal fa-user",
          text: "general",
        },
        {
          icon: "fal fa-store",
          text: "store",
        },
        {
          icon: "fal fa-lock",
          text: "change_password",
        },
      ],

      tab: 0,

      store: {
        name: "John Doe",
        phone: "1234567890",
        address: "123 Main St",
        email: "store@gmail.com",
        register_date: "2021-01-01",
        expiry_date: "2022-01-01",
        product_count: 100,
      },
      password: {
        old: "",
        new: "",
        confirm: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    changePassword() {
      if (this.password.new !== this.password.confirm) {
        alert("New passwords do not match");
        return;
      }
      // Handle password change logic here
      alert("Password changed successfully");
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
