<template>
  <v-row>
    <v-col cols="4">{{ $t("code") }}</v-col>
    <v-col cols="8">{{ item.code }}</v-col>
    <v-col class="my-4" cols="4">{{ $t("image") }}</v-col>
    <v-col class="my-4" cols="8">
      <v-btn text @click="openURL(item.img)">
        <app-img :img="item.img" />
      </v-btn>
    </v-col>
    <v-col cols="4">{{ $t("product_name") }}</v-col>
    <v-col cols="8">{{ item.name }}</v-col>
    <v-col cols="4">{{ $t("supplier") }}</v-col>
    <v-col cols="8">{{ supplier }}</v-col>
    <v-col cols="4">{{ $t("stock") }}</v-col>
    <v-col cols="8">{{ item.stock | NumberFormat }}</v-col>
    <v-col cols="4">{{ $t("total_sale") }}</v-col>
    <v-col cols="8">{{ item.history_sum_qty | NumberFormat }}</v-col>
    <v-col cols="4">{{ $t("category") }}</v-col>
    <v-col cols="8">{{
      $i18n.locale === "en" ? item.category.en_name : item.category.la_name
    }}</v-col>
    <v-col v-if="item.size" cols="4">{{ $t("unit") }}</v-col>
    <v-col v-if="item.size" cols="8"
      >{{ item.size.name }}
      <v-chip
        label
        dark
        :color="
          item.size.unit === 'amount' ? 'orange accent-2' : 'green accent-3'
        "
        small
      >
        {{
          item.size.unit === "amount"
            ? $t("amount")
            : `${$t("pack")}|${item.size.amount}`
        }}</v-chip
      >
    </v-col>
    <v-col cols="4">{{ $t("price_buy") }}</v-col>
    <v-col cols="8">{{ item.price_buy | NumberFormat }}</v-col>
    <v-col cols="4">{{ $t("price_sale") }}</v-col>
    <v-col cols="8">{{
      (item.price_sale - item.tax_amount) | NumberFormat
    }}</v-col>
    <template v-if="taxInclude">
      <v-col cols="4"> {{ $t("tax") }} {{ tax }} </v-col>
      <v-col cols="8">{{ item.tax_amount | NumberFormat }}</v-col>
      <v-col cols="4">{{ $t("price_with_tax") }}</v-col>
      <v-col cols="8">{{ item.price_sale | NumberFormat }}</v-col>
    </template>
    <v-col cols="4">{{ $t("profit") }}</v-col>
    <v-col cols="8">{{ profit | NumberFormat }} / {{ $t("item") }}</v-col>
    <!-- <v-col cols="4">{{ $t("discount") }}</v-col>
    <v-col cols="8">{{ item.history_sum_discount | NumberFormat }}</v-col> -->
    <v-col cols="4">{{ $t("total_sale") }}</v-col>
    <v-col cols="8">{{ item.history_sum_total_price | NumberFormat }}</v-col>
    <v-col cols="4">{{ $t("cash_received") }}</v-col>
    <v-col cols="8">{{
      (item.history_sum_total_price - item.history_sum_discount) | NumberFormat
    }}</v-col>
    <v-col cols="4">{{ $t("expired_date") }}</v-col>
    <v-col cols="8">{{ item?.expired_date ?? $t("none_expired") }}</v-col>
    <v-col cols="4">{{ $t("created_at") }}</v-col>
    <v-col cols="8">{{ item.created_at | moment }}</v-col>
  </v-row>
</template>

<script>
import { myMixin } from "@/mixin/main";
export default {
  mixins: [myMixin],
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    supplier() {
      if (
        this.item.vendor &&
        this.item.vendor.name &&
        this.item.vendor.phone_no
      ) {
        return this.item.vendor.name + " - " + this.item.vendor.phone_no;
      } else if (this.item.vendor && this.item.vendor.name) {
        return this.item.vendor.name;
      } else if (this.item.vendor && this.item.vendor.phone_no) {
        return this.item.vendor.phone_no;
      } else {
        return "";
      }
    },

    profit() {
      const buy = this.item.price_buy;
      const sale = this.item.price_sale;
      const tax = this.item.tax_amount;
      const a = sale - tax;
      return a - buy;
    },
  },
};
</script>

<style></style>
