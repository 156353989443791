<template>
  <section>
    <v-breadcrumbs :items="breadcrumbs" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <router-view />
  </section>
</template>

<script>
export default {
  name: "Layouts",
  data() {
    return {
      breadcrumbs: [],
    };
  },
  mounted() {
    this.setBreadcrumbs();
  },

  watch: {
    $route() {
      this.setBreadcrumbs();
    },
  },

  methods: {
    setBreadcrumbs() {
      this.breadcrumbs = this.$route.matched.map((route) => {
        return {
          text: this.$t(route.name),
          disabled: false,
          href: route.path,
        };
      });
    },
  },
};
</script>

<style></style>
