<template>
  <v-navigation-drawer
    :mini-variant="miniVariant"
    :clipped="clipped"
    v-model="drawer"
    fixed
    app
    class="drawer-left"
    width="255"
  >
    <template v-slot:prepend v-if="user && user.first_name">
      <v-list-item class="px-2">
        <v-badge bordered bottom color="green" dot offset-x="25" offset-y="20">
          <v-list-item-avatar>
            <v-avatar color="#f0f0f0">
              <v-img :src="Img" />
            </v-avatar>
          </v-list-item-avatar>
        </v-badge>

        <v-list-item-content>
          <v-list-item-title> {{ user.first_name }}</v-list-item-title>
          <v-list-item-subtitle>
            {{
              user.branch_id ? user.branch_id.name : user.email
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-list v-if="menus.length">
      <v-list-item v-for="(item, i) in menus" :key="i + 1" :to="item.to">
        <v-list-item-icon>
          <v-icon small>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <v-divider class="ma-0" />
      <div class="pa-2 text-center">
        {{ miniVariant ? version : `${$t("version")} : ${version}` }}
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { myMixin } from "@/mixin/main";
import { toast, confirm } from "@/controllers/alert";
const drawer = localStorage.getItem("drawer") === "true" ? true : false;
export default {
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      clipped: false,
      drawer: drawer,
      miniVariant: false,
      version: process.env.VUE_APP_VERSION,
      dialog: false,
    };
  },

  watch: {
    drawer(val) {
      localStorage.setItem("drawer", val);
    },
  },
  mixins: [myMixin],
  created() {
    if (this.$vuetify.breakpoint.mobile) {
      this.drawer = false;
    }
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      isLoggedIn: "auth/isLoggedIn",
    }),
    Img() {
      return Help.img(this.user.avatar);
    },
  },
  methods: {},
};
</script>

<style></style>
