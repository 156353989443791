<template>
  <v-container fluid>
    <data-table
      :headers="headers"
      :items="items"
      commit="SET_ALL_PRODUCTS"
      :editItem="editItem"
      :deleteItem="deleteItem"
      :footer="false"
    >
      <div slot="activator" class="row">
        <v-col cols="3">
          <v-card-title class="py-0 pl-0">
            {{ $t("payments") }}
          </v-card-title>
        </v-col>
        <v-col cols="4" offset="3" class="text-right">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn color="primary" @click="addNewItem()">
            <v-icon small left> fal fa-plus </v-icon>
            {{ $t("add_payment") }}
          </v-btn>
        </v-col>
      </div>
    </data-table>
    <app-custom-dialog
      v-model="dialog"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField
          label="bank_name"
          v-model="form.bank_name"
          :errorMessages="bankError"
          @input="$v.form.bank_name.$touch()"
          @blur="$v.form.bank_name.$touch()"
        />
        <TTextField
          label="account_no"
          v-model="form.account_no"
          :errorMessages="accountError"
          @input="$v.form.account_no.$touch()"
          @blur="$v.form.account_no.$touch()"
        />
        <TTextField
          label="account_name"
          v-model="form.account_name"
          :errorMessages="accountError"
          @input="$v.form.account_name.$touch()"
          @blur="$v.form.account_name.$touch()"
        />
        <div class="pt-2">{{ $t("choose_currency") }}</div>
        <v-chip-group
          v-model="form.currency"
          mandatory
          column
          active-class="deep-purple--text text--accent-4"
        >
          <v-chip
            v-for="(val, i) in currencies"
            :key="i"
            :value="val.name"
            filter
            outlined
          >
            {{ val.name }}
          </v-chip>
        </v-chip-group>
      </div>
    </app-custom-dialog>
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";
import TTextField from "@/components/global/TextField";
import { success, confirm } from "@/controllers/alert";
import { mapGetters } from "vuex";
import { paymentMixin } from "@/validations/dashboard";

export default {
  components: {
    DataTable,
    TTextField,
  },
  mixins: [paymentMixin],
  data() {
    return {
      search: "",
      dialog: false,
      update: false,
      titleStatus: "add_payment",

      items: {
        data: [],
      },
      form: {
        account_name: "",
        account_no: "",
        bank_name: "",
        currency: "LAK",
      },
    };
  },
  mounted() {
    this.fetchItem();
  },
  computed: {
    ...mapGetters({
      currencies: "index/currencies",
    }),

    headers() {
      return [
        {
          text: this.$t("id"),
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("bank_name"),
          align: "start",
          sortable: true,
          value: "bank_name",
        },
        {
          text: this.$t("account_no"),
          align: "start",
          sortable: true,
          value: "account_no",
        },
        {
          text: this.$t("account_name"),
          align: "start",
          sortable: true,
          value: "account_name",
        },
        {
          text: this.$t("currency"),
          align: "center",
          sortable: true,
          value: "currency",
        },
        {
          text: this.$t("is_active"),
          align: "right",
          sortable: true,
          value: "is_active",
        },
        {
          text: this.$t("action"),
          align: "right",
          sortable: false,
          value: "actions",
        },
      ];
    },
  },
  methods: {
    async fetchItem() {
      try {
        const { data } = await this.$http.get(
          `/bank_accounts?q=${this.search}`
        );
        this.items.data = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    editItem(item) {
      this.form = { ...item };
      this.titleStatus = "edit_payment";
      this.dialog = true;
      this.update = true;
    },

    async deleteItem(item) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`/bank_accounts/${item.id}`);
        if (data.status) {
          this.items.data.splice(
            this.items.data.findIndex((i) => i.id === item.id),
            1
          );
          await success(this, data.message);
        }
      }
    },
    addNewItem() {
      this.titleStatus = "add_payment";
      this.dialog = true;
      this.update = false;
    },
    async submitForm() {
      try {
        let data = {};
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          if (this.update) {
            data = await this.$http.put(
              `/bank_accounts/${this.form.id}`,
              this.form
            );
          } else {
            data = await this.$http.post("/bank_accounts", this.form);
          }
          if (data.status) {
            this.fetchItem();
            this.dialog = false;
            await success(this, data.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
