import {
  required,
  maxLength,
  minLength,
  email,
  sameAs,
  numeric,
} from "vuelidate/lib/validators";
const paymentMixin = {
  validations: {
    form: {
      bank_name: { required },
      account_no: { required, numeric },
      account_name: { required },
    },
  },
  computed: {
    bankError() {
      const errors = [];
      if (!this.$v.form.bank_name.$dirty) return errors;
      const msg = this.$t("validation_required");

      !this.$v.form.bank_name.required && errors.push(msg);
      return errors;
    },
    accountError() {
      const errors = [];
      if (!this.$v.form.account_no.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.account_no.required && errors.push(msg);
      if (!this.$v.form.account_no.numeric) {
        const msg = this.$t("numeric");
        errors.push(msg);
      }
      return errors;
    },
    accountError() {
      const errors = [];
      if (!this.$v.form.account_name.$dirty) return errors;
      const msg = this.$t("validation_required");

      !this.$v.form.account_name.required && errors.push(msg);
      return errors;
    },
  },
};

const bannerMixin = {
  validations: {
    form: {
      title: { required },
      file: { required },
    },
  },
  computed: {
    titleError() {
      const errors = [];
      if (!this.$v.form.title.$dirty) return errors;
      const msg = this.$t("validation_required");

      !this.$v.form.title.required && errors.push(msg);
      return errors;
    },
    fileError() {
      const errors = [];
      if (!this.$v.form.file.$dirty) return errors;
      const msg = this.$t("validation_required");

      !this.$v.form.file.required && errors.push(msg);
      return errors;
    },
  },
};

export { paymentMixin, bannerMixin };
