<template>
  <v-container fluid>
    <v-row>
      <div
        v-for="(item, index) in data"
        :key="index"
        class="col-lg-3 col-md-6 col-sm-6"
      >
        <v-card>
          <v-card-title>
            <h4>{{ $t(item.text) }}</h4>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-content-between">
              <div>
                <h2>{{ item.value }}</h2>
              </div>
              <div>
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-icon left> fal fa-chart-line </v-icon>
        <span class="text-h6"> {{ $t("summary") }} </span>
      </v-col>
      <v-col cols="8">
        <v-card>
          <v-card-title>
            <h4>{{ $t("sale_chart") }}</h4>
          </v-card-title>
          <v-card-text>
            <apexchart
              type="line"
              height="350"
              :options="saleChart.chartOptions"
              :series="saleChart.series"
            ></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            <h4>{{ $t("category_count") }}</h4>
          </v-card-title>
          <v-card-text>
            <apexchart
              type="donut"
              height="350"
              :options="categoryCount.chartOptions"
              :series="categoryCount.series"
            ></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      // Order,products,category,Follower
      data: [],
      categories: [],
      // sale chart
      saleChart: {
        series: [],
        chartOptions: {},
      },
      // category count sale chart with donut chart
      categoryCount: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: "donut",
          },
          dataLabels: {
            enabled: false,
          },
          labels: [],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.getSumarOrder();
    this.getSummary();
    this.getSumCategory();
  },
  methods: {
    async getSumarOrder() {
      try {
        const { data } = await this.$http.get("sum_order_dash");
        const series = data.data.map((item) => item.total);
        this.saleChart = {
          series: [
            {
              name: "Sales",
              data: series,
            },
          ],
          chartOptions: {
            chart: {
              height: 350,
              type: "line",
              zoom: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "straight",
            },
            title: {
              text: "Order Summary",
              align: "left",
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],

            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
              ],
            },
          },
        };
      } catch (error) {
        console.log(error);
      }
    },
    async getSummary() {
      try {
        const { data } = await this.$http.get("get_summary_dash");
        this.data = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getSumCategory() {
      try {
        const { data } = await this.$http.get("sum_category_dash");
        const series = data.data.map((item) => item.products_count);
        const labels = data.data.map((item) => item.la_name);
        this.categoryCount = {
          series: series,
          chartOptions: {
            chart: {
              width: 380,
              type: "donut",
            },
            dataLabels: {
              enabled: false,
            },
            labels: labels,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
        };
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
