<template>
  <div class="main-card">
    <v-card
      rounded
      class="rounded-lg"
      :ripple="false"
      flat
      @click="addToCart(item)"
      :class="item.stock ? '' : ' out-of-stock'"
    >
      <v-card-text class="product-body py-1">
        <v-chip label x-small>{{ item.stock }}</v-chip>
        <div class="product-card">
          <v-img
            class="rounded-lg"
            :src="img(item)"
            lazy-src="/images/pos.png"
            contain
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>

        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title class="caption pb-1">
              {{ productName }}</v-list-item-title
            >
            <v-list-item-subtitle class="product-price">{{
              item.price_sale | NumberFormat
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="total" class="font-weight-bold">
            {{ total }}
          </v-list-item-action>
        </v-list-item>
      </v-card-text>
    </v-card>
    <v-tooltip bottom v-if="haveInCart">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="btn-trash"
          @click="removeCart(item)"
          depressed
          color="red"
          dark
          x-small
          width="30"
          height="30"
        >
          <v-icon small>fal fa-trash</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("remove_product") }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { myMixin } from "@/mixin/main";
import { mainStore } from "@/stores/modules/cart";

export default {
  props: ["item"],
  mixins: [myMixin],
  data() {
    return {
      defineStore: mainStore(),
    };
  },
  computed: {
    total() {
      const value = this.defineStore.cart.find((e) => e.id === this.item.id);
      if (value != null && value != undefined) {
        return value.qty;
      }
    },
    haveInCart() {
      const value = this.defineStore.cart.find((e) => e.id === this.item.id);
      if (value != null && value != undefined) {
        return true;
      } else {
        return false;
      }
    },
    productName() {
      if (
        this.item != null &&
        this.item != undefined &&
        this.item.name != null &&
        this.item.name != undefined
      ) {
        return `${this.item.name}`;
      } else {
        return "";
      }
    },
    stock() {
      // check if item and item.size are not null
      if (this.item && this.item.size && this.item.size.unit === "amount") {
        return this.item.stock;
      }
      // else return item.stock + item.size.unit
      else if (this.item && this.item.size) {
        return `${this.item.stock}/${this.item.size.amount}`;
      }
      return "";
    },
  },
  methods: {
    async removeCart(item) {
      const body = {
        that: this,
        payload: item,
      };
      this.defineStore.removeCart(body);
    },

    async addToCart(item) {
      const body = {
        that: this,
        payload: item,
      };
      this.defineStore.addToCart(body);
    },
  },
};
</script>

<style></style>
