import Vue from "vue";
import store from "@/stores";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import terms from "@/pages/terms";
import register from "@/pages/auth/register";
import verify_new_user from "@/pages/auth/verify_new_user";
import open_store from "@/pages/auth/open_store";
import create_branch from "@/pages/auth/create_branch";
import verify_account from "@/pages/auth/verify_account";
import login from "@/pages/auth/login";
import forgot_password from "@/pages/auth/forgot_password";
import verify_otp from "@/pages/auth/verify_otp";
import new_password from "@/pages/auth/new_password";
import setting from "@/pages/setting";
// expense
import expense from "@/pages/expense/expense";
import expenseCategory from "@/pages/expense/expense-category";

import historySale from "@/pages/history-sale";
import trashProducts from "@/pages/trash-products";
import PageNotFound from "@/pages/404";

// administrator
import administrator from "@/pages/administrator/index";
import orders from "@/pages/administrator/orders";
import feedback from "@/pages/administrator/feedbacks";
import settings from "@/pages/administrator/settings";
import stores from "@/pages/administrator/stores";
import branch from "@/pages/administrator/branch";
import packages from "@/pages/administrator/packages";
import users from "@/pages/administrator/users";
import shopCategories from "@/pages/administrator/store/categories";
import shopCustomers from "@/pages/administrator/store/customers";
import shopProducts from "@/pages/administrator/store/products";
import shopUsers from "@/pages/administrator/store/users";
import shopTicketReport from "@/pages/administrator/store/bill-report";
import invoice from "@/pages/administrator/invoice";

import permission from "@/pages/settings/permission";
import importData from "@/pages/settings/import";
import exportData from "@/pages/settings/export";

// app
import Sale from "@/pages/sale";
import sort from "@/pages/sort-product";
import products from "@/pages/products";
import notify from "@/pages/notify";
import expired from "@/pages/expired";
import bestSale from "@/pages/best-sale";
import overdue from "@/pages/overdue";
import table from "@/pages/tables";
import overview from "@/pages/overview";

// manage
import category from "@/pages/manage/categories";
import productUnit from "@/pages/manage/unit";
import addProduct from "@/pages/manage/add-product";
import editProduct from "@/pages/manage/edit-product";
import managePurchase from "@/pages/manage/purchase";
import manageBarcode from "@/pages/manage/barcode";
import manageSupplier from "@/pages/manage/suppliers";
import manageBranch from "@/pages/manage/branches";
import manageCustomer from "@/pages/manage/customers";
import manageUser from "@/pages/manage/users";
import manageStock from "@/pages/manage/stock";

// report
import billReport from "@/pages/reports/bill-report";
import bill_report_table from "@/pages/reports/bill_report_table";
import reportUsers from "@/pages/reports/report-users";
import reportCategory from "@/pages/reports/category";
import reportUnit from "@/pages/reports/unit";
import reportSupplier from "@/pages/reports/suppliers";
import reportBranch from "@/pages/reports/branches";
import reportCustomers from "@/pages/reports/customers";
import reportUser from "@/pages/reports/users";
import reportPurchase from "@/pages/reports/purchase";
import privacy from "@/pages/privacy";

// landing page
import index from "@/pages/index";
import contact_us from "@/pages/contact_us";
import partners from "@/pages/landing/partners";
import tutorials from "@/pages/landing/tutorials";

// scan
import scan from "@/pages/scan/index";
import cart from "@/pages/scan/cart";
import receipt from "@/pages/scan/receipt";
import kitchen from "@/pages/kitchen";
import customer_screen from "@/pages/customer_screen";
import documents from "@/pages/documents";

// grocery
import grocery from "@/pages/grocery";

// online dashboard
import layouts from "@/pages/dashboard/layouts";
import dashboard from "@/pages/dashboard";
import dashboardBanners from "@/pages/dashboard/banners";
import dashboardProducts from "@/pages/dashboard/products";
import dashboardCategories from "@/pages/dashboard/categories";
import dashboardOrders from "@/pages/dashboard/orders";
import dashboardShipping from "@/pages/dashboard/shipping";
import dashboardPayment from "@/pages/dashboard/payments";
import dashboardUsers from "@/pages/dashboard/users";
import dashboardSettings from "@/pages/dashboard/settings";

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: index,
      meta: {
        layout: "basic",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/contact_us",
      name: "contact_us",
      component: contact_us,
      meta: {
        layout: "basic",
        requiresAuth: false,
        status: [],
      },
    },
    // {
    //   path: "/grocery",
    //   name: "GROCERY",
    //   component: grocery,
    //   meta: {
    //     layout: "basic",
    //     requiresAuth: false,
    //     status: [],
    //   },
    // },
    {
      path: "/partners",
      name: "partners",
      component: partners,
      meta: {
        layout: "basic",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/tutorials",
      name: "tutorials",
      component: tutorials,
      meta: {
        layout: "basic",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/register",
      name: "register",
      component: register,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: privacy,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/sale",
      name: "sale",
      component: Sale,
      meta: {
        requiresAuth: true,
        layout: "default",
        store: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/terms",
      name: "terms",
      component: terms,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/verify-new-user",
      name: "verify_new_user",
      component: verify_new_user,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/open-store",
      name: "open_store",
      component: open_store,
      meta: {
        layout: "blank",
        requiresAuth: true,
        status: [2],
      },
    },
    {
      path: "/open-branch",
      name: "open_branch",
      component: create_branch,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [2],
      },
    },
    {
      path: "/verify-account",
      name: "verify_account",
      component: verify_account,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [2],
      },
    },
    {
      path: "/login",
      name: "login",
      component: login,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/forgot-password",
      name: "forgot_password",
      component: forgot_password,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/verify-otp",
      name: "verify_otp",
      component: verify_otp,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/new-password",
      name: "new_password",
      component: new_password,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/sort-product",
      name: "sort_product",
      component: sort,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },

    {
      path: "/expense",
      name: "expense",
      component: expense,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/expense-category",
      name: "expense_category",
      component: expenseCategory,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },

    // manage
    {
      path: "/manage-category",
      name: "manage_category",
      component: category,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/manage-product-unit",
      name: "manage_product_unit",
      component: productUnit,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/manage-add-product",
      name: "manage_add_product",
      component: addProduct,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/manage-customers",
      name: "manage_customers",
      component: manageCustomer,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/manage-barcode",
      name: "manage_barcode",
      component: manageBarcode,
      meta: {
        layout: "blank",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },

    {
      path: "/manage-branches",
      name: "manage_branches",
      component: manageBranch,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/manage-suppliers",
      name: "manage_suppliers",
      component: manageSupplier,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },

    {
      path: "/manage-purchase",
      name: "manage_purchase",
      component: managePurchase,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },

    {
      path: "/manage-edit-product/:id",
      name: "edit_product",
      component: editProduct,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/best-sale",
      name: "best_sale",
      component: bestSale,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/history-sale",
      name: "history_sale",
      component: historySale,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },

    {
      path: "/products",
      name: "products",
      component: products,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/trash-products",
      name: "trash_products",
      component: trashProducts,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/notify",
      name: "notify",
      component: notify,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/expired",
      name: "expired",
      component: expired,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/overdue",
      name: "overdue",
      component: overdue,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },

    {
      path: "/report-purchase",
      name: "report_purchase",
      component: reportPurchase,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/report-category",
      name: "report_category",
      component: reportCategory,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/report-unit",
      name: "report_unit",
      component: reportUnit,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/report-suppliers",
      name: "report_suppliers",
      component: reportSupplier,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/report-branches",
      name: "report_branches",
      component: reportBranch,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },

    {
      path: "/report-customers",
      name: "report_customers",
      component: reportCustomers,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/report-users",
      name: "report_users",
      component: reportUser,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/report-bill",
      name: "report_bill",
      component: billReport,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/report-bill-table",
      name: "report_bill_table",
      component: bill_report_table,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },

    {
      path: "/user-report/:id",
      name: "user_report",
      component: reportUsers,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },

    {
      path: "/settings-permission",
      name: "permission",
      component: permission,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/manage-users",
      name: "manage_users",
      component: manageUser,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/manage-stock",
      name: "stock",
      component: manageStock,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/table",
      name: "table",
      component: table,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/overview",
      name: "overview",
      component: overview,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/settings-import",
      name: "settings_import",
      component: importData,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/settings-export",
      name: "settings_export",
      component: exportData,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/settings-general",
      name: "settings_general",
      component: setting,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },

    // for admin
    {
      path: "/administrator",
      name: "administrator",
      component: administrator,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },
    {
      path: "/administrator/orders",
      name: "orders",
      component: orders,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },

    {
      path: "/administrator/stores",
      name: "stores",
      component: stores,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },
    {
      path: "/administrator/branch:store_id",
      name: "store_id",
      component: branch,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },
    {
      path: "/administrator/packages",
      name: "packages",
      component: packages,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },
    {
      path: "/administrator/users",
      name: "users",
      component: users,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },
    {
      path: "/administrator/invoice",
      name: "invoice",
      component: invoice,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },
    {
      path: "/administrator/feedback",
      name: "administrator_feedback",
      component: feedback,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },
    {
      path: "/administrator/setting",
      name: "administrator_setting",
      component: settings,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },
    {
      path: "/store-categories/:store_id",
      name: "store_categories",
      component: shopCategories,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },
    {
      path: "/store-customers/:store_id",
      name: "store_customers",
      component: shopCustomers,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },
    {
      path: "/store-products/:store_id",
      name: "store_products",
      component: shopProducts,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },
    {
      path: "/store-users/:store_id",
      name: "store_user",
      component: shopUsers,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },
    {
      path: "/store-ticket/:store_id",
      name: "store_ticket",
      component: shopTicketReport,
      meta: {
        layout: "admin",
        requiresAuth: true,
        status: [1],
      },
    },

    {
      path: "*",
      name: "404",
      component: PageNotFound,
      meta: {
        layout: "blank",
      },
    },
    // scan
    {
      path: "/scan/:store_id/:branch_id/:table_id",
      name: "scan",
      component: scan,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/cart",
      name: "cart",
      component: cart,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/receipt",
      name: "receipt",
      component: receipt,
      meta: {
        layout: "blank",
        requiresAuth: false,
        status: [],
      },
    },
    {
      path: "/kitchen",
      name: "kitchen",
      component: kitchen,
      meta: {
        requiresAuth: true,
        layout: "blank",
        store: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/customer-screen",
      name: "customer_screen",
      component: customer_screen,
      meta: {
        requiresAuth: true,
        layout: "blank",
        store: true,
        status: [2, 3, 4, 5],
      },
    },
    {
      path: "/documents",
      name: "documents",
      component: documents,
      meta: {
        layout: "product",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
    },
    // online dashboard
    // group dashboard,products,categories,orders,users,settings
    {
      path: "/dashboard",
      name: "dashboard",
      component: layouts,
      meta: {
        layout: "online",
        requiresAuth: true,
        status: [2, 3, 4, 5],
      },
      children: [
        {
          path: "/dashboard/index",
          name: "dashboard_index",
          component: dashboard,
          meta: {
            layout: "online",
            requiresAuth: true,
            status: [2, 3, 4, 5],
          },
        },
        {
          path: "/dashboard/banners",
          name: "banners",
          component: dashboardBanners,
          meta: {
            layout: "online",
            requiresAuth: true,
            status: [2, 3, 4, 5],
          },
        },
        {
          path: "/dashboard/products",
          name: "dashboard_products",
          component: dashboardProducts,
          meta: {
            layout: "online",
            requiresAuth: true,
            status: [2, 3, 4, 5],
          },
        },
        {
          path: "/dashboard/categories",
          name: "dashboard_categories",
          component: dashboardCategories,
          meta: {
            layout: "online",
            requiresAuth: true,
            status: [2, 3, 4, 5],
          },
        },
        {
          path: "/dashboard/orders",
          name: "dashboard_orders",
          component: dashboardOrders,
          meta: {
            layout: "online",
            requiresAuth: true,
            status: [2, 3, 4, 5],
          },
        },
        {
          path: "/dashboard/shipping",
          name: "dashboard_shipping",
          component: dashboardShipping,
          meta: {
            layout: "online",
            requiresAuth: true,
            status: [2, 3, 4, 5],
          },
        },
        {
          path: "/dashboard/payments",
          name: "dashboard_payments",
          component: dashboardPayment,
          meta: {
            layout: "online",
            requiresAuth: true,
            status: [2, 3, 4, 5],
          },
        },
        {
          path: "/dashboard/users",
          name: "dashboard_users",
          component: dashboardUsers,
          meta: {
            layout: "online",
            requiresAuth: true,
            status: [2, 3, 4, 5],
          },
        },
        {
          path: "/dashboard/settings",
          name: "settings",
          component: dashboardSettings,
          meta: {
            layout: "online",
            requiresAuth: true,
            status: [2, 3, 4, 5],
          },
        },
      ],
    },
  ],
});
router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters["auth/isLoggedIn"];
  const user = store.getters["auth/user"];
  const { requiresAuth, status } = to.meta;
  const isAdmin = user.role === 1;
  // check if to.pages has childdren pages or not
  const includes = status.includes(user.role);
  // if next page requires auth and user is not logged in
  if (requiresAuth && !isLoggedIn) {
    next("/login");
  } else if (
    // if next page requires auth and user is logged in
    requiresAuth &&
    isLoggedIn &&
    // and user is not admin
    !isAdmin &&
    // and user role is not included in the next page status
    !includes &&
    // and user has stores
    user.stores &&
    // and user has branch
    user.branch_id
  ) {
    // redirect to sale page
    next("/sale");
  } else if (
    // if next page not requires auth and user is logged in
    !requiresAuth &&
    isLoggedIn &&
    // and user is not admin
    !isAdmin &&
    // and user has stores
    user.stores &&
    // and user has branch
    user.branch_id
  ) {
    // redirect to sale page
    next("/sale");
  } else if (
    // if next page requires auth and user is logged in
    requiresAuth &&
    isLoggedIn &&
    // and user is not admin
    !isAdmin &&
    // and user role is not included in the next page status
    !includes &&
    // and user has stores
    user.stores &&
    // and user has no branch
    !user.branch_id
  ) {
    next("/open-store");
  } else if (
    // if next page requires auth and user is logged in
    requiresAuth &&
    isLoggedIn &&
    // and user is not admin
    !isAdmin &&
    // and user role is not included in the next page status
    includes &&
    // and user has stores
    user.stores &&
    // and user has no branch
    !user.branch_id
  ) {
    next("/open-branch");
  } else if (
    // if next page requires auth and user is logged in
    requiresAuth &&
    isLoggedIn &&
    // and user is admin
    isAdmin &&
    // and user role is not included in the next page status
    !includes
  ) {
    // redirect to admin administrator
    next("/administrator");
  } else if (
    // if next page not requires auth and user is logged in
    !requiresAuth &&
    isLoggedIn &&
    // and user is admin
    isAdmin
  ) {
    // redirect to admin administrator
    next("/administrator");
  }

  next();
});
export default router;
