import Vue from "vue";
import Help from "@/utils/helper";
import AxiosPlugin from "@/plugins/axios";
window.Help = Help;
import vuetify from "@/plugins/vuetify";
import print from "@/plugins/print";
import i18n from "@/plugins/i18n";
import router from "@/router";
import store from "@/stores";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMeta from "vue-meta";
import App from "@/App.vue";
// layout
import Default from "@/layouts/default";
import basic from "@/layouts/basic";
import admin from "@/layouts/admin";
import blank from "@/layouts/blank";
import product from "@/layouts/product";
import online from "@/layouts/online";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import VueApexCharts from "vue-apexcharts";
import AppLoading from "@/components/AppLoading";
import TextSearch from "@/components/global/TextSearch";
import AppCustomDialog from "@/components/customDialog";
import AppImg from "@/components/img";
import DatePicker from "@/components/global/DatePicker";
import VueMasonry from "vue-masonry-css";
import { createPinia, PiniaVuePlugin } from "pinia";
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

/* --------------------------------- Module --------------------------------- */
Vue.use(VueMasonry);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.component("AppLoading", AppLoading);
Vue.component("TextSearch", TextSearch);
Vue.component("AppCustomDialog", AppCustomDialog);
Vue.component("AppImg", AppImg);
Vue.component("DatePicker", DatePicker);
Vue.use(VueToast, { position: "top-right" });
Vue.use(AxiosPlugin);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMeta);
/* --------------------------------- Layout --------------------------------- */
Vue.component("default", Default);
Vue.component("basic", basic);
Vue.component("admin", admin);
Vue.component("blank", blank);
Vue.component("product", product);
Vue.component("online", online);

/* --------------------------------- Config --------------------------------- */
Vue.config.productionTip = false;
const app = new Vue({
  vuetify,
  el: "#app",
  pinia,
  store,
  i18n,
  router,
  render: (h) => h(App),
});

export default app;
