<template>
  <v-container fluid>
    <data-table
      :headers="headers"
      :items="items"
      commit="SET_ALL_PRODUCTS"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
      :proStatus="proStatus"
    >
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex">
            <v-card-title class="py-0 pl-0">
              {{ $t("all_product") }}
            </v-card-title>
            <v-chip-group
              v-model="status_filter"
              mandatory
              column
              active-class="deep-purple--text text--accent-4"
            >
              <v-chip
                v-for="(val, i) in status_list"
                :key="i"
                :value="val.value"
                filter
                outlined
              >
                {{ $t(val.text) }}
              </v-chip>
            </v-chip-group>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-2 text-right">
          <TextSelect
            :dense="true"
            :placeholder="false"
            :items="categories"
            v-model="category_id"
            :text="$i18n.locale === 'en' ? 'en_name' : 'la_name'"
            itemValue="id"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-right">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-right">
          <v-btn
            min-width="auto"
            depressed
            dark
            color="primary"
            to="/manage-add-product"
          >
            <v-icon left small>fal fa-plus-circle</v-icon>
            {{ $t("add_new_product") }}
          </v-btn>
        </div>
      </div>
    </data-table>
    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>
    <app-loading :overlay="overlay" />
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";
import AppDetail from "@/components/products/details";
import { success, confirm } from "@/controllers/alert";
import TextSelect from "@/components/global/TextSelect";
import { productSort } from "@/utils/mock";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    DataTable,
    AppDetail,
    TextSelect,
  },
  data() {
    return {
      search: "",
      dialog: false,
      status_filter: "desc",
      form: {},
      status_list: productSort(),
      category_id: {
        id: 0,
        la_name: "ທັງໝົດ",
        en_name: "All",
      },
    };
  },

  mounted() {
    this.fetchItem();
    this.fetchCategory();
  },
  computed: {
    ...mapGetters({
      overlay: "index/overlay",
      category: "index/category",
      items: "index/allProducts",
    }),
    headers() {
      return [
        { text: this.$t("id"), value: "number" },
        { text: this.$t("barcode"), value: "code" },
        { text: this.$t("image"), value: "img" },
        { text: this.$t("name"), value: "name" },
        { text: this.$t("stock"), value: "stock" },
        { text: this.$t("category"), value: "category" },
        { text: this.$t("unit"), value: "size.name" },
        { text: this.$t("price"), value: "price_sale" },
        { text: this.$t("date"), value: "created_at", align: "right" },
        { text: this.$t("status"), align: "right", value: "proStatus" },
        { text: this.$t("manage"), value: "actions", align: "right" },
      ];
    },
    categories() {
      if (
        this.category != null &&
        this.category != undefined &&
        this.category.length
      ) {
        const object = {
          id: 0,
          la_name: "ທັງໝົດ",
          en_name: "All",
        };
        this.category.unshift(object);
        return this.category;
      } else {
        return [];
      }
    },
  },
  watch: {
    status_filter(val, oldVal) {
      if (val !== oldVal) {
        this.fetchItem();
      }
    },
    category_id(val, oldVal) {
      if (val !== oldVal) {
        this.fetchItem();
      }
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchCategory() {
      const body = {
        url: "categories",
        that: this,
        commit: "SET_CATEGORY",
      };
      await this.fetch(body);
    },
    async fetchItem() {
      try {
        const body = {
          url: `get_products_dash?cat_id=${this.category_id.id}&q=${this.search}&sort=${this.status_filter}`,
          that: this,
          commit: "SET_ALL_PRODUCTS",
        };
        await this.fetch(body);
      } catch (error) {
        console.log(error);
      }
    },
    async viewItem({ id }) {
      try {
        const { data } = await this.$http.get(`products/${id}`);
        if (data.status) {
          this.dialog = !this.dialog;
          this.form = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    editItem({ id }) {
      this.$router.push(`/manage-edit-product/${id}`);
    },
    async deleteItem({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`products/${id}`);
        if (data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },
    async proStatus(val) {
      try {
        const { data } = await this.$http.put(`product_active/${val.id}`);
        if (data.status) {
          await this.fetchItem();
          await success(this, data.message);
        } else {
          await errorAlert(this, data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    submitForm() {
      console.log("Add New Product");
    },
  },
};
</script>

<style scoped></style>
