<template>
  <v-container fluid>
    <data-table
      :headers="headers"
      :items="category"
      commit="SET_CATEGORY"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
    >
      <div slot="activator" class="row justify-content-between">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <v-card-title class="px-0 pt-0"> {{ $t("banners") }} </v-card-title>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 offset-lg-5">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 text-end">
          <v-btn depressed color="primary" @click="newItem">
            <v-icon left>fal fa-plus-circle</v-icon>
            {{ $t("new_data") }}
          </v-btn>
        </div>
      </div>
    </data-table>

    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="4">{{ $t("id") }}</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">{{ $t("title") }}</v-col>
          <v-col cols="8">{{ form.title }}</v-col>
          <v-col cols="4">{{ $t("image") }}</v-col>
          <v-col cols="8">
            <v-btn text @click="openURL(form.img)">
              <app-img :img="form.img" />
            </v-btn>
          </v-col>

          <v-col cols="4">{{ $t("desc") }}</v-col>
          <v-col cols="8">{{ form.desc }}</v-col>
          <v-col cols="4">{{ $t("url") }}</v-col>
          <v-col cols="8">{{ form.url }}</v-col>
          <v-col cols="4">{{ $t("is_show") }}</v-col>
          <v-col cols="8">{{ form.is_show ? $t("yes") : $t("no") }}</v-col>
          <v-col cols="4">{{ $t("is_online") }}</v-col>
          <v-col cols="8">{{ form.is_online ? $t("yes") : $t("no") }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField
          label="title"
          v-model="form.title"
          :errorMessages="titleError"
          @input="$v.form.title.$touch()"
          @blur="$v.form.title.$touch()"
        />
        <TTextField label="url" v-model="form.url" />
        <TTextField label="desc" v-model="form.desc" />
        <v-file-input
          v-model="form.file"
          label="file"
          accept="image/*"
          :error-messages="fileError"
          @input="$v.form.file.$touch()"
          @blur="$v.form.file.$touch()"
        />
        <v-switch v-model="form.is_online" :label="$t('is_online')" inset />
        <v-switch v-model="form.is_show" :label="$t('is_show')" inset />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { productCategory } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import { success, confirm } from "@/controllers/alert";
import { myMixin } from "@/mixin/main";
import { bannerMixin } from "@/validations/dashboard";

export default {
  mixins: [bannerMixin, myMixin],
  data: () => ({
    update: false,
    dialogAdd: false,
    dialog: false,
    overlay: false,
    search: "",
    form: {
      file: null,
      is_online: 1,
      title: "",
      desc: "",
      url: "",
      is_online: 1,
      is_show: 1,
    },
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField },
  computed: {
    ...mapGetters({
      category: "index/category",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    headers() {
      return [
        {
          text: this.$t("ID"),
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("title"),
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: this.$t("image"),
          align: "start",
          sortable: true,
          value: "img",
        },
        {
          text: this.$t("desc"),
          align: "start",
          sortable: true,
          value: "desc",
        },
        {
          text: this.$t("url"),
          align: "start",
          sortable: true,
          value: "url",
        },
        {
          text: this.$t("is_show"),
          align: "right",
          sortable: true,
          value: "is_show",
        },
        {
          text: this.$t("action"),
          align: "right",
          sortable: false,
          value: "actions",
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `banners?q=${this.search}`,
        commit: "SET_CATEGORY",
      };
      await this.fetch(body);
    },

    async newItem() {
      this.setDefault();
      this.form.is_online = 1;
      this.form.is_show = 1;
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },

    setDefault() {
      this.form = {
        file: null,
        is_online: 1,
        title: "",
        desc: "",
        url: "",
        is_online: 1,
        is_show: 1,
      };
    },

    editItem(item) {
      this.update = true;
      this.form = item;
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`banners/${id}`);
        if (data.status) {
          this.setDefault();
          // find the id in array and remove it
          this.category.data = this.category.data.filter(
            (item) => item.id !== id
          );
          await success(this, data.message);
        }
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async submitForm() {
      try {
        const header = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const body = new FormData();
        body.append("title", this.form.title);
        body.append("desc", this.form.desc ?? "");
        body.append("url", this.form.url ?? "");
        if (this.form.file) {
          body.append("file", this.form.file);
        }
        body.append("is_online", this.form.is_online ?? 1);
        body.append("is_show", this.form.is_show ?? 1);
        if (this.update) {
          await this.updateBanner(header, body, this.form.id);
        } else {
          await this.addBanner(header, body);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async addBanner(header, body) {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          const { data } = await this.$http.post("banners", body, header);
          if (data.status) {
            this.dialogAdd = !this.dialogAdd;
            this.setDefault();
            // add products_count : 0 in data before push
            data.data.products_count = 0;
            // unshift new item
            this.category.data.push(data.data);
            await success(this, data.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateBanner(header, body, id) {
      try {
        const { data } = await this.$http.post(
          `banners_update/${id}`,
          body,
          header
        );
        if (data.status) {
          this.dialogAdd = !this.dialogAdd;
          this.setDefault();
          await this.fetchItem();
          await success(this, data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
