const head = (title) => {
  return {
    title: title,
    meta: [
      {
        hid: "description",
        name: "description",
        content: "PosSmart - ລະບົບຂາຍສິນຄ້າທີ່ດີທີ່ສຸດ",
      },
    ],
  };
};
const productHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("barcode"), value: "code" },
    { text: prop.$t("image"), value: "img" },
    { text: prop.$t("product_name"), value: "name" },
    { text: prop.$t("stock"), value: "stock" },
    { text: prop.$t("total_sale"), value: "histories_sum_qty" },
    { text: prop.$t("category"), value: "category" },
    { text: prop.$t("unit"), value: "product_unit" },
    { text: prop.$t("cost"), value: "price_buy" },
    { text: prop.$t("price_sale"), value: "price_sale" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("status"), align: "right", value: "proStatus" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const quatationHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("document_no"), value: "quotation_no" },
    { text: prop.$t("customer"), value: "first_name" },
    { text: prop.$t("status"), value: "status" },
    { text: prop.$t("total_items"), value: "total_items" },
    { text: prop.$t("expired_date"), value: "expiry_date" },
    { text: prop.$t("total_amount"), value: "total_amount" },
    { text: prop.$t("vat"), value: "tax_amount" },
    { text: prop.$t("grand_total"), value: "grand_total" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    {
      text: prop.$t("manage"),
      value: "actionsBill",
      align: "right",
    },
  ];
};
const trashProductHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("barcode"), value: "code" },
    { text: prop.$t("image"), value: "img" },
    { text: prop.$t("name"), value: "name" },
    { text: prop.$t("stock"), value: "stock" },
    { text: prop.$t("category"), value: "category" },
    { text: prop.$t("cost"), value: "price_buy" },
    { text: prop.$t("price_sale"), value: "price_sale" },
    { text: prop.$t("delete_date"), align: "right", value: "deleted_at" },
    { text: prop.$t("create_at"), value: "created_at", align: "right" },
    {
      text: prop.$t("manage"),
      value: "actionsProducts",
      align: "right",
      align: "right",
    },
  ];
};
const productsStores = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("barcode"), value: "code" },
    { text: prop.$t("image"), value: "img" },
    { text: prop.$t("name"), value: "name" },
    { text: prop.$t("stock"), value: "stock" },
    { text: prop.$t("notify"), value: "notify_stock" },
    { text: prop.$t("total_sale"), value: "history_sum_qty" },
    { text: prop.$t("category"), value: "category" },
    { text: prop.$t("unit"), value: "size.name" },
    { text: prop.$t("cost"), value: "price_buy" },
    { text: prop.$t("price_sale"), value: "price_sale" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
  ];
};
const notifyProducts = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("id"), value: "code" },
    { text: prop.$t("image"), value: "img" },
    { text: prop.$t("name"), value: "name" },
    { text: prop.$t("stock"), value: "stock" },
    { text: prop.$t("notify"), value: "notify_stock" },
    { text: prop.$t("total_sale"), value: "history_sum_qty" },
    { text: prop.$t("category"), value: "category" },
    { text: prop.$t("unit"), value: "size.name" },
    { text: prop.$t("cost"), value: "price_buy" },
    { text: prop.$t("price_sale"), value: "price_sale" },
    { text: prop.$t("expired_date"), value: "expired_date" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const sortProductHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "number" },
    { text: prop.$t("barcode"), value: "code" },
    { text: prop.$t("image"), value: "img" },
    { text: prop.$t("name"), value: "name" },
    { text: prop.$t("stock"), value: "stock" },
    { text: prop.$t("total_sale"), value: "history_sum_qty" },
    { text: prop.$t("category"), value: "category" },
    { text: prop.$t("unit"), value: "size.name" },
    { text: prop.$t("price_sale"), value: "price_sale" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
  ];
};
const exchangeRate = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("image"), value: "imgCurrency" },
    { text: prop.$t("currency"), value: "currency" },
    { text: prop.$t("buy_rate"), value: "note" },
    { text: prop.$t("sell_rate"), value: "sell_rate" },
  ];
};

const customerHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("customer_name"), value: "first_name" },
    { text: prop.$t("last_name"), value: "last_name" },
    { text: prop.$t("phon_no"), value: "phone_no" },
    { text: prop.$t("point"), value: "point" },
    { text: prop.$t("address"), value: "address" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};

const expenseCategory = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("name_type"), value: "name" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const expenseHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("title"), value: "name" },
    { text: prop.$t("price"), value: "price" },
    { text: prop.$t("type"), value: "typeExpense" },
    { text: prop.$t("category"), value: "category.name" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const productCategory = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("lao_name"), value: "la_name" },
    { text: prop.$t("eng_name"), value: "en_name" },
    { text: prop.$t("qty_product"), value: "products_count" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const productCategoryAdmin = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("lao_name"), value: "la_name" },
    { text: prop.$t("eng_name"), value: "en_name" },
    { text: prop.$t("products_count_shop"), value: "products_count_shop" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const productSizeHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("name"), value: "name" },
    { text: prop.$t("unit"), value: "unit" },
    { text: prop.$t("amount"), value: "amount" },
    { text: prop.$t("qty_product"), value: "products_count" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const userHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("avatar"), value: "avatar" },
    { text: prop.$t("first_name"), value: "first_name" },
    { text: prop.$t("store"), value: "stores.name" },
    { text: prop.$t("role"), value: "userRole" },
    { text: prop.$t("phone_no"), value: "phone_no" },
    { text: prop.$t("email"), value: "email" },
    { text: prop.$t("verify"), value: "is_active" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const historySaleHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("barcode"), value: "product_id.code" },
    { text: prop.$t("image"), value: "proImg" },
    { text: prop.$t("name"), value: "product_id.name" },
    { text: prop.$t("stock"), value: "stock" },
    { text: prop.$t("total_sale"), value: "qty" },
    { text: prop.$t("customer"), value: "customer_id.first_name" },
    { text: prop.$t("status"), value: "productStatus" },
    { text: prop.$t("category"), value: "product_id.category" },
    { text: prop.$t("unit"), value: "product_id.size.name" },
    { text: prop.$t("cost"), value: "priceBuy" },
    { text: prop.$t("price_sale"), value: "priceSale" },
    { text: prop.$t("discount"), value: "totalDiscount" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};

const orderHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("logo"), value: "storeImg" },
    { text: prop.$t("store_name"), value: "store.name" },
    { text: prop.$t("store_phone_no"), value: "store.phone_no" },
    { text: prop.$t("package"), value: "packageName" },
    { text: prop.$t("price"), value: "price" },
    { text: prop.$t("month"), value: "month_count" },
    { text: prop.$t("status"), value: "orderStatus" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actionsOrder", align: "right" },
  ];
};

const expiredHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("store_image"), value: "storeImgLogo" },
    { text: prop.$t("store_name"), value: "name" },
    { text: prop.$t("phon_no"), value: "phone_no" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actionsOrder", align: "right" },
  ];
};
const feedbackHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("Logo"), value: "storelOGO" },
    { text: prop.$t("Store Name"), value: "store.name" },
    { text: prop.$t("Title"), value: "title" },
    { text: prop.$t("Status"), value: "statusFB" },
    { text: prop.$t("Date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};

const packageHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("Icon"), value: "icon" },
    { text: prop.$t("Name"), value: "packName" },
    { text: prop.$t("Day"), value: "day" },
    { text: prop.$t("Month"), value: "month_count" },
    { text: prop.$t("Products"), value: "product_count" },
    { text: prop.$t("Bill"), value: "bill_count" },
    { text: prop.$t("Users"), value: "user_count" },
    { text: prop.$t("Branch"), value: "branch_count" },
    { text: prop.$t("Price"), value: "price" },
    { text: prop.$t("Date"), value: "created_at", align: "right" },
    { text: prop.$t("Manga"), value: "actions", align: "right" },
  ];
};
const overdueHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("code"), value: "code", align: "left" },
    { text: prop.$t("status"), value: "productStatus" },
    { text: prop.$t("customer_name"), value: "customer_id.first_name" },
    { text: prop.$t("phon_no"), value: "customer_id.phone_no" },
    { text: prop.$t("amount"), value: "history_overdue_count" },
    { text: prop.$t("grand_total"), value: "total_price" },
    { text: prop.$t("total_paid"), value: "total_paid" },
    { text: prop.$t("total_overdue"), value: "total_overdue" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};

const historyStock = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("code"), value: "code", align: "left" },
    { text: prop.$t("status"), value: "status", align: "left" },
    { text: prop.$t("maker"), value: "user.first_name", align: "left" },
    { text: prop.$t("branch"), value: "branch.name", align: "left" },
    { text: prop.$t("stock_history_count"), value: "stock_history_count" },
    {
      text: prop.$t("sum_price_buy"),
      value: "stock_history_sum_price_buy",
      align: "right",
    },
    { text: prop.$t("date"), value: "date", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const billReports = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("code"), value: "code", align: "left" },
    { text: prop.$t("seller"), value: "user_name" },
    { text: prop.$t("customer_name"), value: "customer_name" },
    { text: prop.$t("status"), value: "productStatus" },
    { text: prop.$t("quantity"), value: "total_qty", align: "center" },
    { text: prop.$t("sub_total"), value: "sub_total", align: "center" },
    { text: prop.$t("vat"), value: "tax_amount", align: "center" },
    { text: prop.$t("profit"), value: "total_profit", align: "center" },
    { text: prop.$t("grand_total"), value: "grand_total", align: "center" },
    { text: prop.$t("date"), value: "created_at", align: "center" },
    { text: prop.$t("manage"), value: "actionsBill", align: "right" },
  ];
};

const vendorHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("supplier"), value: "name" },
    { text: prop.$t("address"), value: "address" },
    { text: prop.$t("phon_no"), value: "phone_no" },
    { text: prop.$t("mobile_no"), value: "mobile_no" },
    { text: prop.$t("product"), value: "products_count" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const supplierReportHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("supplier"), value: "name" },
    { text: prop.$t("address"), value: "address" },
    { text: prop.$t("phon_no"), value: "phone_no" },
    { text: prop.$t("mobile_no"), value: "mobile_no" },
    { text: prop.$t("product"), value: "products_count" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
  ];
};
const branchReportHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("branch"), value: "name" },
    { text: prop.$t("address"), value: "address" },
    { text: prop.$t("phon_no"), value: "phone_no" },
    { text: prop.$t("users_count"), value: "users_count" },
    { text: prop.$t("product"), value: "products_count" },
    { text: prop.$t("tickets_count"), value: "bill_count" },
    { text: prop.$t("price"), value: "tickets_sum_total_price" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
  ];
};
const branchHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("logo"), value: "logo" },
    { text: prop.$t("branch"), value: "name" },
    { text: prop.$t("address"), value: "address" },
    { text: prop.$t("phon_no"), value: "phone_no" },
    { text: prop.$t("users_count"), value: "users_count" },
    { text: prop.$t("product"), value: "products_count" },
    { text: prop.$t("total_sale"), value: "tickets_sum_total_price" },
    { text: prop.$t("status"), value: "branchStatus" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const paymentReportHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("QR"), value: "qr_code" },
    { text: prop.$t("name"), value: "name" },
    { text: prop.$t("acct_no"), value: "account_no" },
    { text: prop.$t("tickets_count"), value: "tickets_count" },
    { text: prop.$t("total_sale"), value: "tickets_sum_total_price" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
  ];
};
const paymentHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("QR"), value: "qr_code" },
    { text: prop.$t("name"), value: "name" },
    { text: prop.$t("acct_no"), value: "account_no" },
    { text: prop.$t("tickets_count"), value: "tickets_count" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const reportUser = (prop) => {
  return [
    { text: prop.$t("id"), value: "id" },
    { text: prop.$t("image"), value: "avatar" },
    { text: prop.$t("permission"), value: "userStatus" },
    { text: prop.$t("name"), value: "first_name" },
    { text: prop.$t("branch"), value: "branch_id.name" },
    { text: prop.$t("phon_no"), value: "phone_no" },
    { text: prop.$t("email"), value: "email" },
    { text: prop.$t("tickets_count"), value: "tickets_count" },
    { text: prop.$t("total_sale"), value: "tickets_sum_total_paid" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    {
      text: prop.$t("manage"),
      value: "actionsBillUser",
      align: "right",
      align: "right",
    },
  ];
};
const categoryHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("lao_name"), value: "la_name" },
    { text: prop.$t("eng_name"), value: "en_name" },
    { text: prop.$t("sum_stock"), value: "products_sum_stock" },
    { text: prop.$t("total_sale"), value: "products_sale_sum_qty" },
    {
      text: prop.$t("sum_total_price"),
      value: "products_sale_sum_total_price",
    },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const custHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "id" },
    { text: prop.$t("customer_name"), value: "first_name" },
    { text: prop.$t("phon_no"), value: "phone_no" },
    { text: prop.$t("buy_count"), value: "history_buy_count" },
    { text: prop.$t("total_price"), value: "history_buy_sum_total_paid" },
    { text: prop.$t("date"), value: "actions", align: "right" },
  ];
};
const storeHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("logo"), value: "storeImgLogo" },
    { text: prop.$t("name"), value: "name" },
    { text: prop.$t("package"), value: "packageName" },
    { text: prop.$t("products"), value: "product_count" },
    { text: prop.$t("users_count"), value: "user_count" },
    { text: prop.$t("branch_count"), value: "branch_count" },
    { text: prop.$t("types"), value: "store_type_code" },
    { text: prop.$t("end_date"), value: "end_date" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const BRANCH_HEAD = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("name"), value: "name" },
    { text: prop.$t("desc"), value: "desc" },
    { text: prop.$t("address"), value: "address" },
    { text: prop.$t("phone_no"), value: "phone_no" },
    { text: prop.$t("mobile_no"), value: "mobile_no" },
    { text: prop.$t("email"), value: "email" },
    { text: prop.$t("logo"), value: "logo" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actionsOrder", align: "right" },
  ];
};

const HeadTable = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("table"), value: "name" },
    { text: prop.$t("status"), value: "status" },
    { text: prop.$t("date"), value: "created_at" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};
const HeadTop = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("id"), value: "code" },
    { text: prop.$t("image"), value: "img" },
    { text: prop.$t("product_name"), value: "name" },
    { text: prop.$t("price"), value: "price_sale" },
    { text: prop.$t("total_sale"), value: "histories_sum_qty", align: "right" },
    {
      text: prop.$t("total_price"),
      value: "histories_sum_total_price",
      align: "right",
    },
  ];
};

const tableReport = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("code"), value: "code" },
    { text: prop.$t("table"), value: "table.name" },
    { text: prop.$t("quantity"), value: "total_qty" },
    { text: prop.$t("price"), value: "total_price" },
    { text: prop.$t("status"), value: "orderStatus" },
    { text: prop.$t("order_type"), value: "is_online_order" },
    { text: prop.$t("checker"), value: "user.first_name" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actionsBill", align: "right" },
  ];
};

const invoiceHead = (prop) => {
  return [
    { text: prop.$t("id"), value: "index" },
    { text: prop.$t("invoice_id"), value: "invoice_id" },
    { text: prop.$t("store"), value: "store.name" },
    { text: prop.$t("customer"), value: "store.owner.first_name" },
    { text: prop.$t("status"), value: "status" },
    { text: prop.$t("payment_method"), value: "payment_method" },
    { text: prop.$t("payment_status"), value: "payment_status" },
    { text: prop.$t("total_items"), value: "invoice_detail_count" },
    { text: prop.$t("total"), value: "total" },
    { text: prop.$t("date"), value: "created_at", align: "right" },
    { text: prop.$t("manage"), value: "actions", align: "right" },
  ];
};

export {
  head,
  productHead,
  exchangeRate,
  customerHead,
  expenseCategory,
  productCategory,
  expenseHead,
  productSizeHead,
  orderHead,
  userHead,
  billReports,
  historySaleHead,
  expiredHead,
  feedbackHead,
  notifyProducts,
  packageHead,
  sortProductHead,
  historyStock,
  overdueHead,
  productsStores,
  vendorHead,
  branchHead,
  paymentHead,
  branchReportHead,
  supplierReportHead,
  paymentReportHead,
  reportUser,
  categoryHead,
  storeHead,
  custHead,
  trashProductHead,
  BRANCH_HEAD,
  productCategoryAdmin,
  HeadTable,
  HeadTop,
  tableReport,
  quatationHead,
  invoiceHead,
};
