<template>
  <v-text-field
    dense
    class="s-t-b"
    outlined
    color="primary"
    hide-details
    :placeholder="$t(placeholder)"
    v-model="search"
    v-on:keyup.enter="searchPress(search)"
    v-on:click:clear="onClear()"
    clearable
  >
    <template v-slot:append-outer>
      <v-btn depressed color="primary" @click="searchPress(search)">
        <v-icon left small>fal fa-search</v-icon>
        {{ $t("search") }}
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {},
    placeholder: {
      type: String,
      default: "search",
    },
  },
  data: () => ({
    search: "",
  }),
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.search = newVal;
    },
    search(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit("input", newVal);
    },
  },
  methods: {
    searchPress(search) {
      this.$emit("search", search);
    },
    onClear() {
      this.$emit("onClear");
      this.search = "";
      this.$emit("input", "");
    },
  },
};
</script>

<style></style>
