<template>
  <v-container fluid>
    <v-row>
      <div v-for="(item, index) in summaries" :key="index" class="col-lg-2-5">
        <v-card>
          <v-card-title>
            <h4>{{ $t(item.status) }}</h4>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-content-between">
              <div>
                <h2>{{ item.total }}</h2>
              </div>
              <div>
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-icon left> fal fa-chart-line </v-icon>
        <span class="text-h6"> {{ $t("summary") }} </span>
      </v-col>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="items"
          commit="SET_ALL_PRODUCTS"
          :viewItem="viewItem"
          :editItem="editItem"
        >
          <div slot="activator" class="row">
            <v-col cols="2">
              <v-card-title class="py-0 pl-0">
                {{ $t("orders") }}
              </v-card-title>
            </v-col>
            <v-col cols="2" class="text-right">
              <TextSelect
                :dense="true"
                :placeholder="false"
                :items="statusList"
                v-model="status"
                text="text"
                itemValue="value"
              />
            </v-col>
            <v-col cols="2">
              <DatePicker
                :dense="true"
                :placeholder="false"
                label="start_date"
                v-model="start_date"
              />
            </v-col>
            <v-col cols="2">
              <DatePicker
                :dense="true"
                :placeholder="false"
                label="end_date"
                v-model="end_date"
              />
            </v-col>
            <v-col cols="1">
              <v-btn
                depressed
                outlined
                class="text-capitalize mr-2"
                color="primary"
                @click="filterDate(start_date, end_date)"
              >
                <v-icon left small>fal fa-search</v-icon>
                {{ $t("filter") }}
              </v-btn>
            </v-col>

            <v-col cols="3" class="text-right">
              <TextSearch
                v-model="search"
                @search="onSearch()"
                @onClear="
                  search = '';
                  onSearch();
                "
              />
            </v-col>
          </div>
        </data-table>
      </v-col>
    </v-row>
    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      width="800px"
      :title="titleStatus"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="6">
            <AppTitle title="order_id" :value="form?.txn" />
            <AppTitle title="customer" :value="form?.user?.first_name" />
            <AppTitle title="phone_no" :value="form?.user?.phone_no" />
            <AppTitle title="address" :value="form?.user?.address" />
            <AppTitle title="status" :value="$t(form?.status)" />
          </v-col>
          <v-col cols="6">
            <AppTitle title="total_price" :value="form?.total_price" />
            <AppTitle title="total_qty" :value="form?.total_qty" />
            <AppTitle title="orders_count" :value="form?.orders_count" />
            <AppTitle title="date" :value="form?.created_at | moment" />
          </v-col>

          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th scope="col">{{ $t("no") }}</th>
                    <th scope="col">{{ $t("image") }}</th>
                    <th scope="col">{{ $t("name") }}</th>
                    <th scope="col">{{ $t("category") }}</th>
                    <th scope="col" class="text-right">{{ $t("price") }}</th>
                    <th scope="col" class="text-right">{{ $t("amount") }}</th>
                    <th scope="col" class="text-right">{{ $t("total") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in form.orders" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      <app-img :img="data.product.img" width="40" height="40" />
                    </td>
                    <td>{{ data.product.name.substring(0, 8) + ".." }}</td>
                    <td>{{ data.product.category.la_name }}</td>

                    <td class="text-right">
                      {{ data.price | NumberFormat }}
                    </td>
                    <td class="text-right">{{ data.qty | ToNumber }}</td>
                    <td class="text-right">
                      {{ (data.price * data.qty) | NumberFormat }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
    </app-custom-dialog>

    <!-- update status -->
    <app-custom-dialog
      v-model="dialogEdit"
      :title="titleStatus"
      @submit="updateStatus"
    >
      <div slot="activator">
        <TextSelect
          label="status"
          v-model="body.status"
          itemValue="value"
          text="text"
          :items="statusList"
        />
        <TextArea label="noted" v-model="body.note" />
      </div>
    </app-custom-dialog>

    <app-loading :overlay="overlay" />
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";
import TTextField from "@/components/global/TextField";
import { success } from "@/controllers/alert";
import { mapGetters, mapMutations } from "vuex";
import TextSelect from "@/components/global/TextSelect";
import AppTitle from "@/components/settings/app_title";
import { myMixin } from "@/mixin/main";
import TextArea from "@/components/global/TextArea";

// get today date
const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substr(0, 10);

// get tomorrow date
const tomorrow = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60000 + 86400000
)
  .toISOString()
  .substr(0, 10);

export default {
  components: {
    DataTable,
    TTextField,
    TextSelect,
    AppTitle,
    TextArea,
  },
  mixins: [myMixin],
  data() {
    return {
      search: "",
      dialog: false,
      update: false,
      titleStatus: "add_payment",
      start_date: today,
      end_date: tomorrow,
      dialogEdit: false,
      summaries: [],
      statusList: [
        {
          text: this.$t("all"),
          value: "all",
        },
        {
          text: this.$t("processing"),
          value: "processing",
        },
        {
          text: this.$t("shipping"),
          value: "shipping",
        },
        {
          text: this.$t("completed"),
          value: "completed",
        },
        {
          text: this.$t("cancelled"),
          value: "cancelled",
        },
        {
          text: this.$t("refunded"),
          value: "refunded",
        },
      ],
      status: {
        text: this.$t("all"),
        value: "all",
      },
      items: {
        data: [],
      },
      form: {
        orders: [],
      },
      body: {
        status: {},
        note: "",
      },
    };
  },
  mounted() {
    this.getSumary();
    this.fetchItem();
  },
  computed: {
    ...mapGetters({
      overlay: "index/overlay",
      currencies: "index/currencies",
    }),

    headers() {
      return [
        {
          text: this.$t("id"),
          align: "start",
          value: "index",
        },
        {
          text: this.$t("order_id"),
          align: "start",
          value: "txn",
        },

        {
          text: this.$t("customer"),
          align: "start",
          value: "user.first_name",
        },
        {
          text: this.$t("status"),
          align: "start",
          value: "status",
        },
        {
          text: this.$t("total_price"),
          align: "center",
          value: "total_price",
        },
        {
          text: this.$t("orders_count"),
          align: "center",
          value: "orders_count",
        },
        {
          text: this.$t("total_qty"),
          align: "center",
          value: "total_qty",
        },
        {
          text: this.$t("date"),
          value: "date",
          align: "right",
        },

        {
          text: this.$t("action"),
          align: "right",
          value: "actions",
        },
      ];
    },
  },

  watch: {
    status: {
      handler() {
        this.fetchItem();
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations({
      SET_OVERLAY: "index/SET_OVERLAY",
    }),
    async getSumary() {
      try {
        const { data } = await this.$http.get(`/get_sum_order`);
        this.summaries = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchItem() {
      try {
        const param = `q=${this.search}&status=${this.status.value}&start_date=${this.start_date}&end_date=${this.end_date}`;
        const { data } = await this.$http.get(`/get_orders_dash?${param}`);
        this.items = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async viewItem({ id }) {
      try {
        const { data } = await this.$http.get(`get_orders_dash_detail/${id}`);
        this.form = data.data;
        this.titleStatus = "view_order";
        this.dialog = true;
        this.update = false;
      } catch (error) {
        console.log(error);
      }
    },
    editItem(item) {
      // remove the first element of the array statusList
      this.statusList.shift();
      this.body = {
        status: {
          value: item.status,
          text: item.status,
        },
        note: "",
        order_id: item.id,
      };
      this.titleStatus = "update_status";
      this.dialogEdit = true;
      this.update = true;
    },
    onSearch() {
      this.SET_OVERLAY(true);
      setTimeout(() => {
        this.fetchItem();
        this.SET_OVERLAY(false);
      }, 1000);
    },

    async filterDate(start_date, end_date) {
      try {
        this.SET_OVERLAY(true);
        await Help.getDifferentMonth(this, start_date, end_date);
        setTimeout(() => {
          this.fetchItem();
          this.SET_OVERLAY(false);
        }, 1000);
      } catch (err) {
        console.log("error", err.message);
        return;
      }
    },

    async updateStatus() {
      try {
        this.SET_OVERLAY(true);
        const reqBody = {
          status: this.body.status.value,
          note: this.body.note,
          order_id: this.body.order_id,
        };
        const { data } = await this.$http.post(`update_status`, reqBody);
        this.dialogEdit = false;
        success(this, data.message);
        setTimeout(() => {
          this.fetchItem();
          this.SET_OVERLAY(false);
        }, 1000);
        // add the first element of the array statusList
        this.statusList.unshift({
          text: this.$t("all"),
          value: "all",
        });
      } catch (error) {
        console.log(error);
        this.SET_OVERLAY(false);
      }
    },
  },
};
</script>

<style scoped></style>
