<template>
  <v-container fluid>
    <data-table
      :headers="headers"
      :items="items"
      commit="SET_ALL_PRODUCTS"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row">
        <v-col cols="4">
          <v-card-title class="py-0 pl-0"> {{ $t("users") }} </v-card-title>
        </v-col>
        <v-col cols="5" offset="3" class="text-right">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </v-col>
      </div>
    </data-table>
    <app-custom-dialog v-model="dialog" :footer="false" title="details">
      <div slot="activator">
        <AppTitle title="user_id" :value="item.user.id" />
        <AppTitle title="name" :value="item.user.first_name" />
        <AppTitle title="email" :value="item.user.email" />
        <AppTitle title="phone_no" :value="item.user.phone_no" />
        <AppTitle title="date" :value="item.created_at | moment" />
      </div>
    </app-custom-dialog>
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";
import AppTitle from "@/components/settings/app_title";
import { myMixin } from "@/mixin/main";
export default {
  components: {
    DataTable,
    AppTitle,
  },
  mixins: [myMixin],
  data() {
    return {
      search: "",
      dialog: false,
      item: {
        user: {},
      },
      items: {
        data: [],
      },
    };
  },
  mounted() {
    this.fetchItem();
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("user_id"),
          align: "start",
          sortable: true,
          value: "user.id",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "user.first_name",
        },
        {
          text: this.$t("email"),
          align: "start",
          sortable: true,
          value: "user.email",
        },
        {
          text: this.$t("phone_no"),
          align: "start",
          sortable: true,
          value: "user.phone_no",
        },
        {
          text: this.$t("date"),
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$t("action"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ];
    },
  },
  methods: {
    async fetchItem() {
      const { data } = await this.$http.get(
        `get_followers?search=${this.search}`
      );
      this.items = data.data;
    },

    viewItem(item) {
      this.item = item;
      this.dialog = true;
    },
  },
};
</script>
