<template>
  <section>
    <data-table
      :headers="headers"
      :items="users"
      commit="SET_USERS"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
    >
      <div slot="activator" class="row justify-sm-space-between">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex">
            <v-card-title class="py-0 pl-0">
              {{ $t("users") }}
            </v-card-title>
            <v-chip-group
              v-model="status_filter"
              mandatory
              column
              active-class="deep-purple--text text--accent-4"
            >
              <v-chip
                v-for="(val, i) in status_list"
                :key="i"
                :value="val.value"
                filter
                outlined
              >
                {{ $t(val.text) }}
              </v-chip>
            </v-chip-group>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 text-right">
          <v-btn depressed color="primary" @click="newItem">
            <v-icon left>fal fa-plus-circle</v-icon>
            Add New
          </v-btn>
        </div>
      </div>
    </data-table>
    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="details"
      @submit="submitForm"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="4">Id</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">Store Name</v-col>
          <v-col cols="8">{{ form.stores.name }}</v-col>
          <v-col cols="4">First Name</v-col>
          <v-col cols="8">{{ form.first_name }}</v-col>
          <v-col cols="4">Phone No</v-col>
          <v-col cols="8">{{ form.phone_no }}</v-col>
          <v-col cols="4">Email</v-col>
          <v-col cols="8">{{ form.email }}</v-col>
          <v-col cols="4">Role</v-col>
          <v-col cols="8">
            <v-chip
              dark
              :color="RoleColor(form.role)"
              small
              class="text-capitalize"
              label
            >
              {{ userRole(form.role) }}</v-chip
            ></v-col
          >
          <v-col cols="4">Is Verify</v-col>
          <v-col cols="8">
            <v-chip
              dark
              :color="form.is_active ? 'green' : 'red'"
              small
              class="text-capitalize"
              label
            >
              {{ form.is_active ? "active" : "inactive" }}</v-chip
            ></v-col
          >
          <v-col cols="4">Date</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField
          label="first_name"
          :errorMessages="first_nameErrors"
          v-model="form.first_name"
          @input="$v.form.first_name.$touch()"
          @blur="$v.form.first_name.$touch()"
        />
        <TTextField
          label="phone_no"
          :errorMessages="phone_noErrors"
          v-model="form.phone_no"
          @input="$v.form.phone_no.$touch()"
          @blur="$v.form.phone_no.$touch()"
        />
        <TTextField
          :errorMessages="emailErrors"
          label="email"
          v-model="form.email"
          @input="$v.form.email.$touch()"
          @blur="$v.form.email.$touch()"
        />
        <t-password
          v-model="form.password"
          :errorMessages="passwordErrors"
          label="password"
          showKey="showPassword"
          @showPassword="showPassword = !showPassword"
          :type="showPassword"
        />

        <t-password
          v-model="form.confirm_password"
          @showConfirmPassword="showConfirmPassword = !showConfirmPassword"
          :errorMessages="confirm_passwordErrors"
          label="confirm_password"
          showKey="showConfirmPassword"
          :type="showConfirmPassword"
        />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { userHead } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import AppDetail from "@/components/products/details";
import { myMixin } from "@/mixin/main";
import TPassword from "@/components/global/TextPassword";
import TextSelect from "@/components/global/TextSelect";
import { success, confirm } from "@/controllers/alert";
import { CREATE_USER } from "@/validations/admin";

export default {
  mixins: [myMixin, CREATE_USER],
  data: () => ({
    status_filter: null,
    status_list: [
      {
        value: null,
        text: "all",
      },
      {
        value: 1,
        text: "active",
      },
      {
        value: 0,
        text: "inactive",
      },
    ],
    overlay: false,
    search: "",
    showPassword: false,
    showConfirmPassword: false,
    update: false,
    dialogAdd: false,
    dialog: false,

    form: {
      stores: {},
    },
  }),
  async created() {
    await this.fetchItem();
  },
  watch: {
    status_filter() {
      this.fetchItem();
    },
  },
  components: {
    DataTable,

    TextSelect,
    TTextField,
    AppDetail,
    TPassword,
  },
  computed: {
    ...mapGetters({
      users: "index/users",
      packages: "index/packages",
    }),

    titleStatus() {
      return this.update ? "Update" : "Add New";
    },
    headers() {
      return userHead(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `admin_users?q=${this.search}&status=${this.status_filter}`,
        commit: "SET_USERS",
      };
      await this.fetch(body);
    },
    async fetchPackage() {
      const body = {
        that: this,
        url: "packages",
        commit: "SET_PACKAGE",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      await this.fetchPackage();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    editItem({ id }) {
      this.$router.push(`/edit-product/${id}`);
    },

    async deleteItem({ stores }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "Are you sure to block this user?",
      });
      if (isConfirmed) {
        const { data } = await this.$http.post(
          `admin-block?store_id=${stores.id}`
        );
        if (data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async submitForm() {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          const { data } = await this.$http.post("user_add", this.form);
          if (data.status) {
            this.dialogAdd = !this.dialogAdd;
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await success(this, data.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async editItem(item) {
      this.form = item;
      this.update = true;
      this.dialogAdd = !this.dialogAdd;
    },
  },
};
</script>

<style></style>
